import React, { useEffect, useState, useContext, useRef } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns, columnsInvited, useSelectableRow } from "./tableConfig";
import { Radio } from "antd";
import Button from "../../../components/dashboard-layout/button/Button";
import { MdFileUpload } from "react-icons/md";
import "./style.css";
import TransporterForm from "./TransporterForm";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import {
  getDriverInvitations,
  getDrivers,
  inviteWithCsv,
} from "../../../Services/firebase";
import usePagination from "../../../hooks/usePagination";
import { Context } from "../../../Context";
import styled from "styled-components";
import { toast } from "react-toastify";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import Spinner from "../../../components/dashboard-SA/Spinner";

const StyledInputFile = styled.div`
  & input[type="file"] {
    opacity: 0;
    position: absolute;
    z-index: -999;
  }
`;

const SpinnerCsv = styled(Spinner)`
  margin-left: 20px;
  margin-right: 20px;
`;

export default function Transporters({ history }) {
  const { currentUser } = useContext(Context);
  const rowSelectable = useSelectableRow();
  const modal = useDashboardModal();
  const modalCsv = useDashboardModal();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [isExternalUser, setIsExternalUser] = useState(false);
  const [invited, setInvited] = useState(false);
  const [driverInvited, setDriversInvited] = useState([]);
  const [csvResume, setCsvResume] = useState(null);
  const inputFile = useRef(null);
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    resetPagination,
    setPagination,
    pagination,
  } = usePagination();
  const [loadingCsv, setLoadingCsv] = useState(false);
  const onLoadFile = async (e) => {
    setLoadingCsv(true);
    try {
      const files = e.target.files[0];
      const formData = new FormData();
      if (!files) {
        return;
      }
      formData.append("file", files);
      const token = window.localStorage.getItem("_token");
      const response = await (await inviteWithCsv(formData, token)).json();
      setCsvResume(response);
      toast.success("Invitacions cargadas exitosamente");
      setLoadingCsv(false);
      modalCsv.showModal();
    } catch (error) {
      toast.error(
        error.message || "Ha ocurrido un error intentando cargar el csv"
      );
      setLoadingCsv(false);
    }
  };

  const tableActionButtons =
    currentUser.type === "ORGANIZATION_ADMIN" ? (
      <>
        <StyledInputFile>
          <input type="file" ref={inputFile} onChange={(e) => onLoadFile(e)} />
        </StyledInputFile>
        {!loadingCsv ? (
          <Button
            type="secondary"
            icon={<MdFileUpload size={18} />}
            actionButton={(e) => {
              e.preventDefault();
              if (inputFile && inputFile.current) {
                inputFile.current.click();
              }
            }}
          >
            Importar Excel
          </Button>
        ) : (
          <SpinnerCsv />
        )}
        <Button type="primary" actionButton={modal.showModal}>
          + Crear transportador
        </Button>
      </>
    ) : null;

  useEffect(() => {
    const onGetDrivers = async () => {
      try {
        setLoading(true);
        const { data } = await getDrivers(page, itemsPerPage, isExternalUser);
        const formatedData = data?.drivers?.map((driver) => ({
          id: driver.id,
          status: "Activo",
          email: driver?.User?.email,
          idNumber: driver?.idNumber,
          organization: driver?.Organization?.name || "-",
          userData: {
            name: `${driver?.User?.firstName} ${driver?.User?.lastName}`,
            avatar:
              "https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_1280.png",
          },
          type: "Transportador propio",
        }));
        setPagination({
          ...data.pagination,
          total: data.pagination.total,
        });
        setDrivers(formatedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    onGetDrivers();
  }, [isExternalUser, invited, setPagination, page, itemsPerPage]);

  useEffect(() => {
    const onGetDriverInvitations = async () => {
      setLoading(true);
      try {
        const { data } = await getDriverInvitations(page, itemsPerPage);
        const formatedData = data?.invitations?.map((driver) => ({
          id: driver.id,
          status: "Inactivo",
          email: driver.email,
          personalId: driver?.idNumber,
          organization: driver?.Organization?.name || "-",
          variant: driver?.variant,
        }));
        setPagination({
          ...data.pagination,
          total: data.pagination.totalItems,
        });

        setDriversInvited(formatedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (invited) {
      onGetDriverInvitations();
    }
  }, [invited, itemsPerPage, page, setPagination]);

  const updateDrivers = (newInvited) => {
    if (invited) {
      const driversArr = driverInvited.slice();
      driversArr.unshift({
        id: newInvited.id,
        status: "Inactivo",
        email: newInvited.email,
        personalId: newInvited?.idNumber,
        userData: {
          name: `${newInvited.email}`,
          avatar:
            "https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_1280.png",
        },
        plate: "ABC - 001",
        type: "Transportador invitado",
      });
      setDriversInvited(driversArr);
    }
  };

  const onClickRow = (e, row) => {
    if (invited) {
      return;
    }
    if (
      e.target.parentElement.className.includes("icons") ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    history.push(`/transporters/${row.id}`);
  };

  const setColumns = (columns) => {
    if (currentUser?.type === "SUPER_ADMIN" && !isExternalUser) {
      return [
        ...columns.slice(0, -1),
        {
          title: "Organización",
          dataIndex: "organization",
          key: "organization",
          sortDirections: ["descend"],
        },
        ...columns.slice(-1),
      ];
    }
    return columns;
  };
  return (
    <>
      <DashboardSectionTitle>Transportadores</DashboardSectionTitle>
      <Radio.Group defaultValue="OWN" buttonStyle="solid">
        <Radio.Button
          value="OWN"
          onClick={() => {
            setIsExternalUser(false);
            setInvited(false);
            resetPagination();
          }}
        >
          Transportadores propios
        </Radio.Button>
        <Radio.Button
          value="OUTSOURCED"
          onClick={() => {
            setIsExternalUser(true);
            setInvited(false);
            resetPagination();
          }}
        >
          Transportadores terceros
        </Radio.Button>
        <Radio.Button
          value="INVITED"
          onClick={() => {
            setInvited(true);
            resetPagination();
          }}
        >
          Transportadores invitados
        </Radio.Button>
      </Radio.Group>
      <DashboardTable
        columns={invited ? setColumns(columnsInvited) : setColumns(columns)}
        rowSelectable={rowSelectable}
        tableTitle="Lista de transportadores"
        buttonActionText="+ Crear transportador"
        data={invited ? driverInvited : drivers}
        customButton={tableActionButtons}
        loading={loading}
        page={page}
        pagination={pagination}
        itemsPerPage={itemsPerPage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        onClickRow={onClickRow}
      />
      <TransporterForm modalSetting={modal} updateDrivers={updateDrivers} />

      {csvResume && (
        <DashboardModal
          title={"Resumen carga CSV:"}
          handleOk={() => {
            modalCsv.handleCancel();
            setCsvResume(null);
          }}
          footer={null}
          handleCancel={modalCsv.handleCancel}
          visible={modalCsv.visible}
        >
          <ul>
            <li>Procesados: {csvResume?.PROCESSED}</li>
            <li>No procesados: {csvResume?.UNPROCESSABLE}</li>
            <li>Invitaciones existentes: {csvResume?.EXISTING_INVITATION}</li>
            <li>Usuarios existentes: {csvResume?.EXISTING_USER}</li>
          </ul>
        </DashboardModal>
      )}
    </>
  );
}
