import React from "react";

export const columns = [
    {
      title: "ID Transacción",
      key: "id",
      dataIndex: "id",
      
      sortDirections: ["descend"],
    },
    {
      title: "N° de manifiesto",
      key: "manifestNumber",
      dataIndex: "manifestNumber",
      
      sortDirections: ["descend"],
    },
    {
      title: "Nombre del transportador",
      dataIndex: "driverName",
      key: "driverName",
      sortDirections: ["descend"],
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
  
      sortDirections: ["descend"],
    },
    {
      title: "Valor transferido",
      dataIndex: "mount",
      key: "mount",
  
      sortDirections: ["descend"],
    },
  ];