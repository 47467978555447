import React from "react";
import { Tag, Avatar } from "antd";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => {
      let color;
      switch (status) {
        case "Suspendido":
          color = "volcano";
          break;
        case "Activo":
          color = "green";
          break;
        case "Inactivo":
          color = "volcano";
          break;
        default:
          color = "volcano";
          break;
      }
      return (
        <>
          <Tag color={color}>{status}</Tag>
        </>
      );
    },
    sortDirections: ["descend"],
  },

  {
    title: "Nombre",
    dataIndex: "userData",
    key: "userData",
    render: (userData) => (
      <>
        <Link to="/transporters/d432432">
          <Avatar size="small" src={userData.avatar} />
          <span style={{ color: "#585858", marginLeft: "1em" }}>
            {userData.name}
          </span>
        </Link>
      </>
    ),
    sortDirections: ["descend"],
  },

  {
    title: "Correo electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },
  {
    title: "Cédula",
    dataIndex: "idNumber",
    key: "idNumber",
    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    dataIndex: "",
    key: "x",
    render: () => (
      <FlexContainerHelper justify="space-around">
        <MdModeEdit size={18} />
        {/* <MdBlock size={18} />
        <MdDelete size={18} /> */}
      </FlexContainerHelper>
    ),
  },
];

export const columnsInvited = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => {
      let color;
      switch (status) {
        case "Suspendido":
          color = "volcano";
          break;
        case "Activo":
          color = "green";
          break;
        case "Inactivo":
          color = "volcano";
          break;
        default:
          color = "volcano";
          break;
      }
      return (
        <>
          <Tag color={color}>{status}</Tag>
        </>
      );
    },
    sortDirections: ["descend"],
  },

  {
    title: "Correo electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },
  {
    title: "Tipo",
    dataIndex: "variant",
    key: "id",
    render: (data) =>
      data === "OWN" ? "Transportador propio" : "Transportador tercero",
    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    dataIndex: "",
    key: "x",
    render: () => (
      <FlexContainerHelper justify="space-around">
        <MdModeEdit size={18} />
        {/* <MdBlock size={18} />
        <MdDelete size={18} /> */}
      </FlexContainerHelper>
    ),
  },
];
export function useSelectableRow() {
  //const [selectedRowKeys, setSelectedRowkeys] = useState([]);
  /*const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowkeys({ selectedRowKeys });
  };*/

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({}),
  };

  return rowSelection;
}

export const columnsTransactions = [
  {
    title: "ID Transacción",
    key: "id",
    dataIndex: "id",

    sortDirections: ["descend"],
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Producto/Manifiesto",
    dataIndex: "product",
    key: "product",
    sortDirections: ["descend"],
  },

  {
    title: "Valor transferido",
    dataIndex: "mount",
    key: "mount",

    sortDirections: ["descend"],
  },
];
