import React, { useState, useEffect } from "react";
import { HeaderContainer } from "../Allies/AllyProfile";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { ButtonsContainer } from "../Clients/NewClient";
import Button from "../../../components/dashboard-layout/button/Button";
import { BsPlus, BsFillPeopleFill } from "react-icons/bs";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { DatePicker, Row, Col } from "antd";
import { FaShoppingBag } from "react-icons/fa";
import { columnsWithdrawal } from "./utils";
import {
  getPaymentIntents,
  getInvoiceTotalOrg,
  getInvoiceTotalPartner,
} from "../../../Services/firebase";
import { useHistory, Link } from "react-router-dom";
import SearchBar from "../../../components/dashboard-layout/search-bar/SearchBar";
import styled from "styled-components";
import moment from "moment";
const { RangePicker } = DatePicker;

const LinkStyled = styled(Link)`
  color: inherit;
  text-decoration: none;
  outline: 0;
  &:hover {
    color: inherit;
    text-decoration: none;
    outline: 0;
  }
`;

const HomeSA = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [amountTotalOrg, setAmountTotalOrg] = useState(null);
  const [amountTotalPartner, setAmountTotalPartner] = useState(null);
  const history = useHistory();
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const onChangePage = (val) => {
    setPaginationWithdrawal({ ...paginationWithdrawal, page: val });
  };
  const [paginationWithdrawal, setPaginationWithdrawal] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);

  const icon = (color) => <BsPlus size={14} color={color} />;
  const renderWithdrawRequest = async () => {
    setLoading(true);
    try {
      const request = await getPaymentIntents(
        paginationWithdrawal.page,
        paginationWithdrawal.pageSize,
        dateRange,
        "ORGANIZATION",
        idFilter,
        nameFilter
      );
      const formattedData = request.data.paymentIntents.map((withdrawal) => {
        return {
          id: withdrawal?.id,
          value: withdrawal?.value,
          status: withdrawal?.status,
          user: withdrawal?.User || "-",
          idNumber: withdrawal?.User?.Driver?.idNumber || "-",
          organization: "-",
          createdAt: withdrawal?.createdAt,
          paidAt: withdrawal?.paidAt,
        };
      });
      setWithdrawals(formattedData);
      setPaginationWithdrawal({
        ...paginationWithdrawal,
        page: request.data.pagination.currentPage,
        total: request.data.pagination.totalItems,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getIndicatorsValues = async () => {
    setLoadingIndicators(true);
    try {
      const responseOrganizations = await getInvoiceTotalOrg();
      const responsePartner = await getInvoiceTotalPartner(true);
      setAmountTotalOrg(responseOrganizations.data);
      setAmountTotalPartner(responsePartner.data);
      setLoadingIndicators(false);
    } catch (e) {
      console.log(e);
      setLoadingIndicators(false);
    }
  };

  const onChangeNameFilter = (e) => {
    setNameFilter(e.target.value);
  };
  const onChangeIdFilter = (e) => {
    setIdFilter(e.target.value);
  };
  useEffect(() => {
    const timeout = setTimeout(renderWithdrawRequest, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [paginationWithdrawal.page, idFilter, nameFilter, dateRange]);

  useEffect(() => {
    getIndicatorsValues();
  }, []);

  return (
    <>
      <HeaderContainer>
        <DashboardSectionTitle style={{ marginTop: 12 }}>
          Dashboard
        </DashboardSectionTitle>
      </HeaderContainer>
      <Row justify="end">
        <RangePicker
          defaultValue={[moment().subtract(30, "days"), moment()]}
          ranges={{
            Hoy: [moment(), moment()],
            "Mes en curso": [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
            Semana: [moment().startOf("week"), moment().endOf("week")],
            "Últimos 30 días": [moment().subtract(30, "days"), moment()],
          }}
          onChange={(dates) =>
            setDateRange([
              moment(dates[0]).startOf("day").format(),
              moment(dates[1]).endOf("day").format(),
            ])
          }
        />
      </Row>
      <Row justify="end">
        {amountTotalOrg && amountTotalPartner && (
          <>
            <Col span={8} style={{ width: 338, marginRight: 25 }}>
              <DashboardItem
                title="Total pendiente facturado por Aliados"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  amountTotalPartner
                )}`}
                factor=""
                icon={<FaShoppingBag />}
                iconColor="#F84B05"
                iconBg="#F84B0512"
                factorStatus="up"
                minheight="80px"
              />
            </Col>
            <Col span={8}>
              <DashboardItem
                title="Total pendiente facturado por Clientes"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  amountTotalOrg
                )}`}
                factor=""
                icon={<BsFillPeopleFill />}
                iconColor="#00BE6D"
                iconBg="#E5F9F0"
                factorStatus="down"
                minheight="80px"
              />
            </Col>
          </>
        )}
      </Row>
      <br />
      <DashboardTable
        columns={columnsWithdrawal}
        tableTitle="Solicitudes de retiro"
        data={withdrawals}
        loading={loading}
        onClickRow={(val, record) => {
          history.push(`/withdrawals/${record.id}`);
        }}
        page={paginationWithdrawal.currentPage}
        pagination={paginationWithdrawal}
        onChangePage={onChangePage}
        searchbarPlaceholder="Buscar por cédula de transportador"
        customButton={
          <>
            <SearchBar
              pholder={"Buscar por nombre de transportador"}
              bcolor={"#F6F9FE"}
              elmwidth="40%"
              onChange={onChangeNameFilter}
            />
          </>
        }
        onChangeSearchBar={onChangeIdFilter}
      />
    </>
  );
};

export default HomeSA;
