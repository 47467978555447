import React, { useState, useEffect, useContext } from "react";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columnsTransactions } from "./tableConfig";
import { useParams } from "react-router-dom";
import {
  getDriverTransactions,
  getDriverTransactionsSA,
} from "../../../Services/firebase";
import moment from "moment";
import { Context } from "../../../Context";

export default function TransporterTransactions({ dateRange }) {
  const { currentUser } = useContext(Context);
  const params = useParams();
  const [clientTransactions, setClientTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchBarActiveVal, setSearchbarActiveVal] = useState("");
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  const onGetClientTransactions = async (pag) => {
    setLoading(true);
    try {
      const response =
        currentUser.type === "SUPER_ADMIN"
          ? await getDriverTransactionsSA(
              pag.page,
              pag.pageSize,
              dateRange,
              parseInt(params.id)
            )
          : await getDriverTransactions(
              pag.page,
              pag.pageSize,
              dateRange,
              parseInt(params.id)
            );
      const responseFormatted = response.data.response.data.map((elm) => ({
        id: elm?.TransactionId || "-",
        manifestNumber: elm?.manifestNumber || "-",
        date: moment(elm?.createdAt).format("MMM DD, YYYY  hh:mm a") || "-",
        mount: `$ ${new Intl.NumberFormat("de-DE").format(elm?.value)}`,

        driverPhoto: elm?.urlProfileImage,
        product:
          `${elm?.productName}` || `${elm?.manifestNumber}` || `Transferencia`,
        organization: getDestination(elm) || "-",
      }));
      setClientTransactions(responseFormatted);
      setPagination({
        ...pagination,
        page: response.data.response.pagination.currentPage,
        total: response.data.response.pagination.total,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetClientTransactions(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBarActiveVal]);

  useEffect(() => {
    onGetClientTransactions(pagination);
  }, [pagination.page, pagination.pageSize, dateRange]);

  const getDestination = (elm) => {
    if (elm?.type === "TRANSFER" || elm?.type === "WITHDRAWAL_MONEY") {
      return elm?.value > 0
        ? `${elm?.origin?.firstName} ${elm?.origin?.lastName}`
        : `${elm?.destination?.firstName} ${elm?.destination?.lastName}`;
    } else if (elm?.type === "ADVANCE" || elm?.type === "BUY_PRODUCT") {
      return elm?.value > 0
        ? `${elm?.origin?.name}`
        : `${elm?.destination?.name}`;
    } else {
      return "EFECTY";
    }
  };

  const onChangePage = (val) => {
    setPagination({ ...pagination, page: val });
  };

  const onChangeItemsPerPage = (index, val) => {
    setPagination({ ...pagination, pageSize: val });
  };
  return (
    <>
      <DashboardTable
        columns={columnsTransactions}
        tableTitle={`Total de Transacciones: ${pagination.total}`}
        data={clientTransactions}
        customButton={null}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        loading={loading}
        onChangeSearchBar={onChangeSearchbar}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
      />
    </>
  );
}
