import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columnsProduct, ArchiveActionButon } from "../config/productTable";
import { getAllyProducts } from "../../../Services/firebase";
import Button from "../../../components/dashboard-layout/button/Button";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import ProductForm from "./ProductForm";
import { Context } from "../../../Context";

export default function AllyProducts({ isProfile }) {
  const { currentUser } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [changeTable, setChangeTable] = useState(false);
  const [searchBarActiveVal, setSearchbarActiveVal] = useState("");
  const params = useParams();
  const modal = useDashboardModal();
  const onGetProducts = async () => {
    setLoading(true);
    try {
      const { data } = await getAllyProducts(!isProfile && parseInt(params.id));
      const dataOrdered = data.sort((elm) =>
        elm.status !== "DISABLED" ? -1 : 1
      );
      const formatedRows = dataOrdered.map((row) => {
        const formatData = {
          id: row?.id,
          name: row?.name,
          type: row?.type || "-",
          unitName:
            row?.commissionType === "PERCENT_PER_TOTAL"
              ? "Porcentaje"
              : "Pesos",
          commission:
            row?.commissionType === "PERCENT_PER_TOTAL"
              ? `${row?.commissionValue}%`
              : `$ ${new Intl.NumberFormat("de-DE").format(
                  row?.commissionValue
                )}`,
          branchId: params.id,
          action: (
            <ArchiveActionButon
              productId={row?.id}
              setChangeTable={setChangeTable}
            />
          ),
          status: row?.status,
        };
        setPagination({ ...pagination });
        return formatData;
      });
      setProducts(formatedRows);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  const handleProductCreation = () => {
    modal.handleCancel();
    onGetProducts();
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetProducts(), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBarActiveVal]);
  useEffect(() => {
    if (changeTable) {
      onGetProducts();
      setChangeTable(false);
    }
  }, [changeTable]);
  return (
    <>
      <DashboardTable
        columns={columnsProduct}
        tableTitle="Productos"
        data={products}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        loading={loading}
        searchbarPlaceholder="Buscar productos"
        onChangeSearchBar={onChangeSearchbar}
        rowClassName={(record, index) => {
          return record.status === "DISABLED" ? "row-transparent" : "";
        }}
        customButton={
          currentUser.type === "SUPER_ADMIN" ? (
            <Button type="primary" actionButton={modal.showModal}>
              {"+ Crear producto"}
            </Button>
          ) : null
        }
      />
      <ProductForm
        modalSetting={modal}
        handleAfterProductCreation={handleProductCreation}
      />
    </>
  );
}
