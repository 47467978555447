import React, { useState, useEffect } from "react";
import AuthInput from "../../../components/auth-layout/forms/input";
import PasswordConfirm from "../../../components/auth-layout/forms/PasswordConfirm";
import { MdMarkunread } from "react-icons/md";
import {
  validateEmail,
  validateMinLength,
  validateUppercase,
  matchStringValidate,
} from "../../../utils/validations";
import {
  FormAuthStyle,
  AuthSubmitBtn,
} from "../../../components/auth-layout/styledComponents";
import {
  validateUserInvited,
  signup,
  signInWithEmailAndPassword,
  updateUser,
} from "../../../Services/firebase";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { Link } from "react-router-dom";

export default function RegisterForm() {
  const [formState, updateForm] = useState({
    formValid: false,
    emailValid: false,
    emailError: "",
    passwordError: "",
    passwordConfirmed: false,
    loading: false,
    error: false,
    passwordUppercaseValid: false,
    passwordMinlengthValid: false,
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    lastName: "",
    nameError: "",
    lastNameError: "",
  });
  const history = useHistory();
  const [invitation, setIsInvited] = useState({ isInvited: false, status: "" });
  const [loadingInvitation, setLoadingInvitation] = useState(false);
  const showPasswordFields = (validEmail) => {
    return validEmail && invitation.isInvited ? (
      <>
        <AuthInput
          inputType="text"
          inputName="name"
          inputLabel="Nombre"
          error={!!formState.nameError}
          errorMessage={formState.nameError}
          dataValue={formState.name}
          callbackChange={handleNameChange}
        />
        <AuthInput
          inputType="text"
          inputName="lastName"
          inputLabel="Apellido"
          error={!!formState.lastNameError}
          errorMessage={formState.lastNameError}
          dataValue={formState.lastName}
          callbackChange={handleLastNameChange}
        />
        <PasswordConfirm
          handleChange={handlePasswordChange}
          validUppercase={formState.passwordUppercaseValid}
          validMinlength={formState.passwordMinlengthValid}
          dataValuePassword={formState.password}
          dataValuePasswordConfirm={formState.passwordConfirm}
          handlePasswordConfirmChange={handlePasswordConfirmChange}
          validMatch={formState.passwordConfirmed}
        />
      </>
    ) : (
      ""
    );
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const passwordVal = e.target.value;
    const newState = {
      ...formState,
      password: passwordVal,
    };
    updateForm(newState);
  };
  const handleNameChange = (e) => {
    e.preventDefault();
    const nameVal = e.target.value;
    const newState = {
      ...formState,
      name: nameVal,
    };
    updateForm(newState);
  };
  const handleLastNameChange = (e) => {
    e.preventDefault();
    const lastNameVal = e.target.value;
    const newState = {
      ...formState,
      lastName: lastNameVal,
    };
    updateForm(newState);
  };

  const validatePassword = (passwordVal) => {
    const validName = !!formState.name ? "" : "Nombre es requerido.";
    const validLastName = !!formState.lastName ? "" : "Apellido es requerido";
    const validPasswordUppercase = validateUppercase(passwordVal);
    const validPasswordMinlength = validateMinLength(passwordVal, 8);
    const passwordMatch = matchStringValidate(
      passwordVal,
      formState.passwordConfirm
    );
    const newState = {
      ...formState,
      passwordUppercaseValid: validPasswordUppercase,
      passwordMinlengthValid: validPasswordMinlength,
      passwordConfirmed: passwordMatch,
      nameError: validName,
      lastNameError: validLastName,
    };
    updateForm(newState);
  };

  const handlePasswordConfirmChange = (e) => {
    e.preventDefault();
    const passwordVal = e.target.value;
    updateForm({ ...formState, passwordConfirm: passwordVal });
  };

  const validateConfirmPassword = (passwordVal) => {
    const passwordMatch = matchStringValidate(passwordVal, formState.password);
    updateForm({
      ...formState,
      passwordConfirmed: passwordMatch,
    });
  };

  useEffect(() => {
    validatePassword(formState.password);
  }, [
    formState.password,
    formState.passwordConfirm,
    formState.name,
    formState.lastName,
  ]);

  useEffect(() => {
    validateConfirmPassword(formState.passwordConfirm);
  }, [formState.passwordConfirm]);

  const actionForm = (e) => {
    e.preventDefault();
    if (formState.formValid) {
      console.log("Send form");
    }
  };

  const isValidForm = ({
    name,
    lastName,
    emailValid,
    passwordMinlengthValid,
    passwordUppercaseValid,
    passwordConfirmed,
  }) =>
    name &&
    lastName &&
    emailValid &&
    passwordMinlengthValid &&
    passwordUppercaseValid &&
    passwordConfirmed;

  const displayPasswordFields = (e) => {
    e.preventDefault();
    const email = e.target.value;
    const newState = {
      ...formState,
      email: email,
    };
    updateForm({
      ...newState,
    });
  };

  const validateEmailFormat = (email) => {
    const validEmail = validateEmail(email);
    const errorMessage = validEmail
      ? ""
      : "Inserte un Correo electrónico válido";
    const newState = {
      ...formState,
      emailValid: validEmail,
      emailError: errorMessage,
    };
    updateForm(newState);
  };

  const validateEmailInvitation = async (email) => {
    setLoadingInvitation(true);
    try {
      const response = await validateUserInvited(email);
      setIsInvited({
        isInvited: response.data.found && response.data.status === "invited",
        status: response.data.status || "",
      });
      if (!response.data.found) {
        updateForm({ ...formState, emailError: "Email no encontrado" });
      }
      if (response.data.found && response.data.status !== "invited") {
        updateForm({
          ...formState,
          emailError: `Email registrado`,
        });
      }
      setLoadingInvitation(false);
    } catch (e) {
      console.log(e);
      setLoadingInvitation(false);
    }
  };

  const handleFormValidation = (newState) => {
    const validForm = isValidForm(newState);
    updateForm({
      ...newState,
      formValid: validForm,
    });
  };

  useEffect(() => {
    handleFormValidation(formState);
  }, [
    formState.emailValid,
    formState.passwordMinlengthValid,
    formState.passwordUppercaseValid,
    formState.passwordConfirmed,
    formState.nameError,
    formState.lastNameError,
  ]);

  useEffect(() => {
    let timeout = null;
    if (formState.emailValid) {
      timeout = setTimeout(() => {
        validateEmailInvitation(formState.email);
      }, 400);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [formState.emailValid]);

  useEffect(() => {
    let timeout = setTimeout(() => validateEmailFormat(formState.email), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [formState.email]);

  const onRegisterUser = async () => {
    if (!formState.formValid) {
      return;
    }
    updateForm({ ...formState, loading: true });
    try {
      const response = await signup(formState.email, formState.password);
      if (response.data.customToken) {
        toast.success("Usuario registrado satisfactoriamente.");
        await signInWithEmailAndPassword(formState.email, formState.password);
        await updateUser({
          userId: response.data.id,
          firstName: formState.name,
          lastName: formState.lastName,
        });
        history.push("/");
      }
      updateForm({ ...formState, loading: false });
    } catch (e) {
      console.log(e);
      toast.error(e?.error?.message || e?.message || "Error inesperado");
      updateForm({ ...formState, loading: false });
    }
  };

  return (
    <FormAuthStyle onSubmit={actionForm}>
      <AuthInput
        inputType="email"
        inputName="email"
        inputLabel="Correo electrónico"
        error={!formState.emailValid || !invitation.isInvited}
        errorMessage={
          formState.emailError === "Email registrado" ? (
            <span>
              {formState.emailError}{" "}
              <Link to={`/login?email=${formState.email}`}>Inicia sesión</Link>
            </span>
          ) : (
            formState.emailError
          )
        }
        icon={loadingInvitation ? <Spinner /> : <MdMarkunread />}
        callbackChange={displayPasswordFields}
        dataValue={formState.email}
      />
      {showPasswordFields(formState.emailValid)}
      {!formState.loading ? (
        <AuthSubmitBtn
          formInvalid={!formState.formValid}
          disabled={!formState.formValid}
          onClick={onRegisterUser}
        >
          Registrarme
        </AuthSubmitBtn>
      ) : (
        <Spinner />
      )}
    </FormAuthStyle>
  );
}
