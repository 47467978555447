import React, { useState } from "react";
import {
  InputLayout,
  SearchContainer,
  SearchOptions,
  SearchOption,
  SearchedSelect,
} from "../dashboard-layout/styledComponents";
import {
  LabelText,
  Text,
} from "../../containers/dashboard-sa/Clients/NewClient";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AiFillCloseCircle } from "react-icons/ai";
import Spinner from './Spinner';
import Box from '../Box';

const DropdownSearch = ({
  onSelect,
  onChange,
  data,
  selected,
  removeSelectedUser,
  disabled,
  label = "Conductor o Vehículo",
  loading
}) => {
  const [focus, setFocus] = useState(false);

  const onClickOption = (driverId) => {
    onSelect(driverId);
    setFocus(false);
  };

  return (
    <SearchContainer>
      <LabelText style={{ margin: 0 }}>{label}</LabelText>
      {(selected && (
        <SearchedSelect disabled={disabled}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{ backgroundColor: "#87d068", marginRight: 10 }}
              icon={<UserOutlined />}
            />
            <Text style={{ margin: 0, padding: 0 }} color="#0A181D">
              {selected?.User?.firstName} {selected?.User?.lastName}
            </Text>
          </div>
          {!disabled && (
            <AiFillCloseCircle
              color="#76889A"
              size={18}
              style={{ cursor: "pointer" }}
              onClick={removeSelectedUser}
            />
          )}
        </SearchedSelect>
      )) || (
        <InputLayout
          type="text"
          placeholder="Ingrese conductor o placa"
          size="large"
          onClick={() => setFocus(true)}
          onChange={onChange}
        />
      )}
      {/* {data.length > 0 && ( */}
        <SearchOptions focus={focus}>
          {loading ? (

            <Box display="flex" height="60px" justifyContent="center" alignItems="center">
              <Spinner />
            </Box>
          ): data.map((user, i) => (
            <SearchOption onClick={() => onClickOption(user.idNumber)} key={i}>
              <Avatar
                style={{ backgroundColor: "#87d068", marginRight: 10 }}
                icon={<UserOutlined />}
              />
              <div>
                <Text style={{ margin: 0, padding: 0 }} color="#0A181D">
                  {user.idNumber}
                </Text>
                <Text style={{ margin: 0, padding: 0 }} color="#0A181D">
                  {user?.User?.firstName} {user?.User?.lastName}
                </Text>
              </div>
            </SearchOption>
          ))}
          
        </SearchOptions>
      {/* )} */}
    </SearchContainer>
  );
};

export default DropdownSearch;
