import React, {useState} from "react";
import { Switch } from 'antd';
import { Link } from "react-router-dom";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import { MdModeEdit ,MdBlock, MdDelete } from "react-icons/md";
import styled, {css} from 'styled-components';
import {setBranchProducts} from '../../../Services/firebase';

const SwitchBlue = styled(Switch)`

    & .ant-switch-handle::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${props => props.active ? "#1645b8" : "#fff" };
      border-radius: 50%;
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      content: "";
    }
    & .ant-switch-handle {
      width: 25px;
      height: 25px;
      top: -3px;
      ${props => props.active ?  ""  : css`left:0;` };
    }


      background-color: ${props => props.active ? "#E8EEFC" : "#747474" };
      height: 18px;
`;

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id, record) => (
      <Link to={`/ally/${record.partnerId}/branchs/${id}`} style={{ color: "#000000a6" }}>
        {id}
      </Link>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",
    
    sortDirections: ["descend"],
  },
  {
    title: "Correo electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },
  {
    title: "Teléfono",
    dataIndex: "phone",
    key: "phone",

    sortDirections: ["descend"],
  },
  {
    title: "Dirección",
    dataIndex: "address",
    key: "address",

    sortDirections: ["descend"],
  },
  {
    title: 'Acciones',
    key: 'action',
    render: (text, record) => (
      <FlexContainerHelper justify="space-around" onClick={(e)=> {e.stopPropagation(); e.preventDefault();}}>
        <Link style={{color: "inherit"}} to={`ally/${record.partnerId}/branchs/edit/${record.id}`}><MdModeEdit  size={18} /></Link>
        <MdBlock size={18} />
        <MdDelete size={18} />
      </FlexContainerHelper>
    ),
  },
];
export const columnsTransactions = [
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",
    
    sortDirections: ["descend"],
  },
  {
    title: "Producto",
    dataIndex: "product",
    key: "product",
    sortDirections: ["descend"],
  },
  {
    title: "Fecha/Hora",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "mount",
    key: "mount",

    sortDirections: ["descend"],
  },
];
export const columnsUsers = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    sortDirections: ["descend"],
  },
  {
    title: "Correo electrónico",
    dataIndex: "email",
    key: "email",

    sortDirections: ["descend"],
  },
  {
    title: "Tipo de usuario",
    dataIndex: "type",
    key: "type",

    sortDirections: ["descend"],
  },
  {
    title: "Acciones",
    dataIndex: "",
    key: "",

    sortDirections: ["descend"],
  },
];
export const columnsProduct = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    sortDirections: ["descend"],
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",

    sortDirections: ["descend"],
  },
  {
    title: "Nombre de la unidad",
    dataIndex: "unitName",
    key: "unitName",

    sortDirections: ["descend"],
  },
  {
    title: "Comisión",
    dataIndex: "comission",
    key: "comission",

    sortDirections: ["descend"],
  },
  { 
    title: 'Acciones',
    key: 'action',
    render: (component, record) => {
      return record.status !== "DISABLED" ? <SwitchContainer active={record.active} productId={record.id} branchId={record.branchId} /> : null;
    },
  },
];

const SwitchContainer = ({active, productId, branchId}) => {
 
  const [checked, setChecked] = useState(active);
  const changeProductStatus = async (status, productId, branchId) => {
    try {
      const data = { active: status, productId: productId, branchId: branchId};
      const response = await setBranchProducts(data);
      setChecked(status);
    }  catch(e) {
      console.log(e.response || e);
    }
    
  }
  return <SwitchBlue active={checked ? 1 : 0} checked={checked} onChange={()=>changeProductStatus(!checked, productId, branchId)}  />
}