import moment from 'moment';
const getRangeDates = () => {
  const day = moment().format('d');
  const start = () => {
    const weekDay = moment().format('dddd');
    if(weekDay === 'Lunes') {
      return moment().format();
    }
    return moment().subtract(day -1, 'days').format();
  }
  const end = () => {
    const weekDay = moment().format('dddd');
    if(weekDay === 'Domingo') {
      return moment().format();
    }
    return moment().add(7 - day, 'days').format();
  }

  return {
    start,
    end
  }
};

export default getRangeDates;