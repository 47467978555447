import React, { useContext, useEffect, useState } from "react";
import { Col, Row, DatePicker } from "antd";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import { useSelectableRow } from "../../dashboard/transporters/tableConfig";
import {
  DashboardCard,
  DashboardCardTitle,
  DashboardSectionTitle,
  IconCardWrapper,
  Indicator,
} from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import TransporterForm from "../../dashboard/transporters/TransporterForm";
import { StatusItem } from "../../../components/dashboard-SA/InvoicesItem";
import { getInvoices, getInvoiceTotal } from "../../../Services/firebase";
import { Context } from "../../../Context";
import Box from "../../../components/Box";
import { FaLongArrowAltUp } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import moment from "moment";
import Spinner from "../../../components/dashboard-SA/Spinner";
import usePagination from "../../../hooks/usePagination";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },

  {
    title: "Fecha de creación",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Rango de Facturación",
    dataIndex: "range",
    key: "range",

    sortDirections: ["descend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ current, text }) => (
      <StatusItem status={current}>{text}</StatusItem>
    ),

    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "amount",
    key: "amount",

    sortDirections: ["descend"],
  },
];

const getTagColor = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "error";
  }

  if (status === "PENDING_PAY") {
    return "warning";
  }

  if (status === "PAID") {
    return "success";
  }
};
const getTagText = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "Pendiente por verificar";
  }

  if (status === "PENDING_PAY") {
    return "Pendiente por pagar a Hoy+";
  }

  if (status === "PAID") {
    return "Pagado";
  }
};

const BillingClients = ({ history }) => {
  const rowSelectable = useSelectableRow();
  const modal = useDashboardModal();
  const [loading, setLoading] = useState(false);
  const [loadingTotals, setLoadingTotals] = useState(false);
  const { authUser } = useContext(Context);
  const [totalInvoice, setTotalInvoice] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();

  useEffect(() => {
    const onGetInvoices = async () => {
      try {
        setLoading(true);
        const { data } = await getInvoices(page, itemsPerPage, dateRange);
        const formatedRows = data?.data?.map((row) => ({
          id: row.id,
          date: moment(row.initDate).format("DD/MM/YYYY"),
          range: `${moment(row.initDate).format("MMMM DD, hh:mm a")} - ${moment(
            row.endDate
          ).format("MMMM DD, hh:mm a")}`,
          amount: `$ ${new Intl.NumberFormat("de-DE").format(row.value)}`,
          status: {
            current: getTagColor(row.status),
            text: getTagText(row.status),
          },
        }));
        setPagination(data?.pagination);
        setInvoices(formatedRows);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    const onGetInvoiceTotal = async () => {
      try {
        setLoadingTotals(true);
        const { data } = await getInvoiceTotal();
        setTotalInvoice(data.totalPendingInvoices);
        setLoadingTotals(false);
      } catch (error) {
        setLoadingTotals(false);
        console.log(error);
      }
    };
    if (authUser) {
      onGetInvoices();
      onGetInvoiceTotal();
    }
  }, [authUser, itemsPerPage, page, setPagination, dateRange]);

  const onClickRow = (e, row) => {
    if (
      e.target.parentElement.className.includes("icons") ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    history.push(`/invoice/${row.id}`);
  };

  return (
    <>
      <Row align="middle">
        <Col span={12}>
          <DashboardSectionTitle>Facturación</DashboardSectionTitle>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
        </Col>
      </Row>
      <Box display="flex" justifyContent="flex-end">
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
        >
          <DashboardCard minheight="123px">
            <section>
              <DashboardCardTitle>Valor a pagar hoy a Hoy+</DashboardCardTitle>
              <h2>
                {loadingTotals ? (
                  <Spinner />
                ) : (
                  `$ ${new Intl.NumberFormat("de-DE").format(totalInvoice)}`
                )}
              </h2>
              <Indicator color="#00BE6D">
                <FaLongArrowAltUp />
                5%
              </Indicator>
              <span>desde el último mes</span>
            </section>
            <aside>
              <IconCardWrapper color="#1D00FF" background="#4605F812">
                <MdAttachMoney />
              </IconCardWrapper>
            </aside>
          </DashboardCard>
        </Col>
      </Box>

      <DashboardTable
        columns={columns}
        rowSelectable={rowSelectable}
        pagination={pagination}
        tableTitle="Lista de Facturas"
        buttonActionText="+ Crear transportador"
        data={invoices}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        page={page}
        loading={loading}
        onClickRow={onClickRow}
      />
      <TransporterForm modalSetting={modal} />
    </>
  );
};

export default BillingClients;
