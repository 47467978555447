import React from "react";
import { SearchInput } from "./styleComponents";
import { SearchOutlined } from "@ant-design/icons";

export default function SearchBar({
  pholder = "Buscar...",
  bcolor = "white",
  elmwidth = "auto",
  onChange,
}) {
  return (
    <SearchInput
      size="large"
      placeholder={pholder}
      bcolor={bcolor}
      prefix={<SearchOutlined color={"#76889A"} />}
      elmwidth={elmwidth}
      onChange={onChange}
    />
  );
}
