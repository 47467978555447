import styled from "styled-components";

export const FooterStyle = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-intems: center;
  color: white;
  font-size: 12px;
  z-index: 2;
  & p {
    text-align: center;
  }
  & a {
    color: white;
    outline: 0;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
  }
`;
