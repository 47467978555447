import React, { useEffect, useContext, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
//import requireAuth from "./containers/dashboard/requireAuth";
import AuthLayout from "./components/auth-layout/auth-layout";
import DashboardLayout from "./components/dashboard-layout/DashboardLayout";
import Home from "./containers/dashboard/home/Home";
import Login from "./containers/auth/login/login";
import Register from "./containers/auth/register/Register";
import ResetPassword from "./containers/auth/reset-password/ResetPassword";
import ForgetPassword from "./containers/auth/forget-password/ForgetPassword";
import ServiceOrders from "./containers/dashboard/service-orders/ServiceOrders";
import EditServiceOrder from "./containers/dashboard/service-orders/EditServiceOrder";
import Transporters from "./containers/dashboard/transporters/Transporters";
import TransporterDetail from "./containers/dashboard/transporters/TransporterDetail";
import Users from "./containers/dashboard/users/Users";
import UserProfile from "./containers/dashboard/users/UserProfile";
import Clients from "./containers/dashboard-sa/Clients/Clients";
import NewClient from "./containers/dashboard-sa/Clients/NewClient";
import Allies from "./containers/dashboard-sa/Allies/Allies";
import NewAlly from "./containers/dashboard-sa/Allies/NewAlly";
import EditAlly from "./containers/dashboard-sa/Allies/EditAlly";
import AllyProfile from "./containers/dashboard-sa/Allies/AllyProfile";
import HomeSA from "./containers/dashboard-sa/Home/HomeSA";
import ClientProfile from "./containers/dashboard-sa/Clients/ClientProfile.jsx";
import InvoiceDetailsSA from "./containers/dashboard-sa/Billing/BillingDetails";
import { Context } from "./Context";
import { withRouter } from "react-router-dom";
import { onAuthStateChanged } from "./Services/firebase";
import DashboardAlly from "./containers/dashboard-ally/dashboard/Dashboard";
import Branchs from "./containers/dashboard-ally/Branchs/Branchs";
import BranchDetail from "./containers/dashboard-ally/Branchs/BranchDetail";
import CreateBranch from "./containers/dashboard-ally/Branchs/create/CreateBranch";
import EditBranch from "./containers/dashboard-ally/Branchs/edit/EditBranch";
import BillingClients from "./containers/dashboard/Billing/Billing";
import AlliedProfile from "./containers/dashboard-ally/Profile/Profile";

import "react-toastify/dist/ReactToastify.css";
import BillingAllies from "./containers/dashboard-ally/Billing/Billing";
import BillingAllyDetails from "./containers/dashboard-ally/Billing/BillingDetails";
import AllyUsers from "./containers/dashboard-ally/Users/AllyUsers";
import InvoicesDetails from "./containers/dashboard/Billing/BillingDetails";
import OrganizationInvoices from "./containers/dashboard-sa/Billing/OrganizationInvoices";
import AllyInvoices from "./containers/dashboard-sa/Billing/AllyInvoices";
import WithdrawalDetail from "./containers/dashboard-sa/Withdrawals/WithdrawalDetail";
import UsersSuperAdmin from "./containers/dashboard-sa/Users/UsersSuperAdmin";
import EfectyList from "./containers/dashboard-sa/Efecty/EfectyList";
import EfectyDetails from "./containers/dashboard-sa/Efecty/EfectyDetails";
import EfectyPayments from "./containers/dashboard-sa/Efecty/EfectyPayments";
import Comissions from "./containers/dashboard-sa/Comission/Comissions";
import { ROUTES } from "./utils/constants";

function App({ history, match }) {
  const { isAuth, setIsAuth, setAuthUser, currentUser, authUser } = useContext(
    Context
  );
  const [status, setStatus] = useState("loading");
  useEffect(() => {
    onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken()
          .then((idToken) => {
            window.localStorage.setItem("_token", idToken);
          })
          .catch((e) => {
            console.log(e);
          });
        setAuthUser(user);
        setIsAuth(true);
      } else {
        // history.push("/login");
      }
      setStatus("success");
    });

    // eslint-disable-next-line
  }, [isAuth]);

  if (status === "loading") {
    return (
      <Switch>
        <AuthLayout>
          <Route>
            <div>Loading</div>
          </Route>
        </AuthLayout>
      </Switch>
    );
  }

  return (
    <React.Fragment>
      {/* Auth routes */}

      {!isAuth && (
        <AuthLayout>
          <Switch>
            <Route path="/" exact>
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            </Route>
            <Route path="/login" exact component={Login} />

            <Route path="/register" exact>
              <Register />
            </Route>
            <Route path="/forget-password" exact>
              <ForgetPassword />
            </Route>
            <Route path="/password-reset/:token">
              <ResetPassword />
            </Route>
          </Switch>
        </AuthLayout>
      )}

      {/* client routes */}

      {isAuth && currentUser?.type === "ORGANIZATION_ADMIN" && (
        <DashboardLayout history={history}>
          <Switch>
            <Route path="/" exact>
              <Home history={history} />
            </Route>

            <Route path="/service-order/create" exact>
              <ServiceOrders view="create" />
            </Route>

            <Route path="/service-order/edit/:id">
              <EditServiceOrder view="edit" />
            </Route>
            <Route path="/service-order/:id">
              <ServiceOrders view="view" />
            </Route>
            <Route path="/transporters" exact component={Transporters}></Route>
            <Route
              path="/transporters/:id"
              component={TransporterDetail}
            ></Route>
            <Route path="/users" exact>
              <Users />
            </Route>
            <Route path="/profile">
              <UserProfile />
            </Route>
            <Route path="/invoices" component={BillingClients} />
            <Route path="/invoice/:id" component={InvoicesDetails} />
            <Route path="/login" exact>
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            </Route>
            <Route path="*" exact component={() => "Pagina no encontrada"} />
          </Switch>
        </DashboardLayout>
      )}

      {/* Super Admin routes */}

      {isAuth && currentUser?.type === "SUPER_ADMIN" && (
        <DashboardLayout history={history}>
          <Switch>
            <Route path="/" exact component={HomeSA} />
            <Route path="/clients" exact component={Clients} />
            <Route path="/clients/create" exact component={NewClient} />
            <Route path={ROUTES.allAllies()} exact component={Allies} />
            <Route path={ROUTES.createAllies()} exact component={NewAlly} />
            <Route
              path={ROUTES.editAllies({ id: ":id" })}
              component={EditAlly}
            />
            <Route path={ROUTES.ally({ id: ":id" })} component={AllyProfile} />
            <Route path="/clients/:id" component={ClientProfile} />
            <Route
              path="/invoices/organization"
              component={OrganizationInvoices}
            />
            <Route path="/invoices/allies" component={AllyInvoices} />
            <Route path="/invoice/:id" component={InvoiceDetailsSA} />
            <Route path="/transporters" exact component={Transporters} />
            <Route path="/transporters/:id" component={TransporterDetail} />
            <Route path="/efecty" exact component={EfectyList} />
            <Route path="/efecty/payments" exact component={EfectyPayments} />
            <Route path="/efecty/:id" exact component={EfectyDetails} />
            <Route path="/comisiones" exact component={Comissions} />
            {/* <Route path="/profile" component={UserProfile} /> */}
            <Route path="/users" exact component={UsersSuperAdmin} />
            <Route
              path="/withdrawals/:withdrawalId"
              component={WithdrawalDetail}
            />
            <Route path="/login" exact>
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            </Route>
            <Route path="*" exact component={() => "Pagina no encontrada"} />
          </Switch>
        </DashboardLayout>
      )}
      {isAuth &&
        currentUser?.type === "PARTNER_ADMIN" &&
        currentUser?.type !== "SUPER_ADMIN" && (
          <DashboardLayout history={history}>
            <Switch>
              <Route path="/" exact component={DashboardAlly} />
              <Route path="/branchs" exact component={Branchs} />
              <Route path="/branchs/create" exact component={CreateBranch} />
              <Route
                path="/ally/:partnerId/branchs/edit/:id"
                exact
                component={EditBranch}
              />
              <Route
                path="/ally/:partnerId/branchs/:id"
                component={BranchDetail}
              />
              <Route path="/profile" exact component={AlliedProfile} />
              <Route path="/invoices" exact component={BillingAllies} />
              <Route path="/invoice/:id" exact component={BillingAllyDetails} />
              <Route path="/users" exact component={AllyUsers} />
              <Route path="/login" exact>
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              </Route>
              <Route path="*" exact component={() => "Pagina no encontrada"} />
            </Switch>
          </DashboardLayout>
        )}
    </React.Fragment>
  );
}

export default withRouter(App);
