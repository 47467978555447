import React, { useState, useEffect } from "react";
import AuthInput from "../../../components/auth-layout/forms/input";
import { MdMarkunread, MdVisibility, MdVisibilityOff } from "react-icons/md";
import {
  FormAuthStyle,
  AuthSubmitBtn,
} from "../../../components/auth-layout/styledComponents";
// import { Link } from "react-router-dom";
import useInput from "../../../hooks/useInput";
import {
  signInWithEmailAndPassword,
  validateUserInvited,
} from "../../../Services/firebase";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { useHistory } from "react-router-dom";

export default function LoginForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const email = useInput("", isSubmitting);
  const password = useInput("", isSubmitting);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const onLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!email.value || !password.value) {
      return;
    }
    if (error) {
      setError(null);
    }
    try {
      const { data } = await validateUserInvited(email.value);
      if (data.blocked) {
        throw { code: 41, message: "Usuario bloqueado" };
      }
      await signInWithEmailAndPassword(email.value, password.value);
      props.history.push("/");
    } catch (error) {
      if (error.code !== 41) {
        if (error?.code?.includes("auth/user-not-found")) {
          email.setError("No existe este usuario");
        }
        if (error?.code?.includes("auth/wrong-password")) {
          password.setError("Contraseña incorrecta");
        }
      } else {
        setError(error);
      }
    }
  };

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    if (params.get("email")) {
      const emailParam = params.get("email");
      email.onSetValue(emailParam);
    }
  }, [history]);

  return (
    <FormAuthStyle onSubmit={onLogin}>
      <AuthInput
        inputType="email"
        inputNmae="email"
        inputLabel="Correo electrónico"
        dataValue={email.value}
        error={email.error}
        errorMessage={email.error}
        callbackChange={email.onChange}
        icon={<MdMarkunread />}
      />
      <AuthInput
        inputType={showPassword ? "text" : "password"}
        inputNmae="password"
        inputLabel="Contraseña "
        error={password.error}
        errorMessage={password.error}
        dataValue={password.value}
        callbackChange={password.onChange}
        icon={
          showPassword ? (
            <MdVisibility
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <MdVisibilityOff
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
            />
          )
        }
      />
      <ErrorMessage error={error}>{error?.message || ""}</ErrorMessage>
      <AuthSubmitBtn>Continuar</AuthSubmitBtn>
    </FormAuthStyle>
  );
}
