import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columnsProduct } from "./tableConfig";
import { getBranchProducts } from "../../../Services/firebase";

export default function BranchProducts() {
  const { id } = useParams();
  const [branchProducts, setBranchProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [searchBarActiveVal, setSearchbarActiveVal] = useState("");
  const onGetBranchProducts = async () => {
    setLoading(true);
    try {
      const { data } = await getBranchProducts(parseInt(id));
      const dataOrdered = data.sort((elm) =>
        elm.status !== "DISABLED" ? -1 : 1
      );
      const formatedRows = dataOrdered.map((row) => {
        const formatData = {
          id: row?.id,
          name: row?.name,
          type: row?.type || "-",
          unitName:
            row?.commissionType === "PERCENT_PER_TOTAL"
              ? "Porcentaje"
              : "Pesos",
          comission:
            row?.commissionType === "PERCENT_PER_TOTAL"
              ? `${row?.commissionValue}%`
              : `$ ${new Intl.NumberFormat("de-DE").format(
                  row?.commissionValue
                )}`,
          active: row?.active,
          branchId: id,
          status: row?.status,
        };
        return formatData;
      });
      setBranchProducts(formatedRows);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetBranchProducts(), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBarActiveVal]);
  return (
    <>
      <DashboardTable
        columns={columnsProduct}
        tableTitle="Productos"
        data={branchProducts}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        loading={loading}
        searchbarPlaceholder="Buscar productos"
        onChangeSearchBar={onChangeSearchbar}
        rowClassName={(record, index) => {
          return record.status === "DISABLED" ? "row-transparent" : "";
        }}
      />
    </>
  );
}
