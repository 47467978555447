import { useState } from 'react';

const usePagination = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pagination, setPagination] = useState(null);

  const onChangePage = (page) => {
    setPage(page);
  }

  const onChangeItemsPerPage = (current, size) => {
    setItemsPerPage(size);
  }



  return {
    pagination,
    page,
    itemsPerPage,
    resetPagination: () => {
      setPage(1);
      setItemsPerPage(10);
    },
    onChangePage,
    onChangeItemsPerPage,
    setPagination
  }
}

export default usePagination;