import React from "react";
import { Link } from "react-router-dom";
import { FlexContainerHelper } from "../../../components/dashboard-layout/styledComponents";
import { MdModeEdit ,MdBlock, MdDelete } from "react-icons/md";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id, record) => (
      <Link to={`/ally/${record.partnerId}/branchs/${id}`} style={{ color: "#000000a6" }}>
        {id}
      </Link>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",
    
    sortDirections: ["descend"],
  },
  {
    title: "Correo electrónico",
    dataIndex: "email",
    key: "email",
    sortDirections: ["descend"],
  },
  {
    title: "Tipo de Usuario",
    dataIndex: "userType",
    key: "userType",

    sortDirections: ["descend"],
  },
  {
    title: 'Acciones',
    key: 'action',
    render: (text, record) => (
      <FlexContainerHelper justify="space-around" onClick={(e)=> {e.stopPropagation(); e.preventDefault();}}>
        <Link style={{color: "inherit"}} to={`users/edit/${record.id}`}><MdModeEdit  size={18} /></Link>
        <MdBlock size={18} />
        <MdDelete size={18} />
      </FlexContainerHelper>
    ),
  },
];
