import React from "react";
import LogoFooter from "../../../assets/img/saLogo.png";
import { FooterNav } from "./styledComponents";

export default function Footer() {
  return (
    <FooterNav>
      <p>
        Version 1.4.0 <img src={LogoFooter} alt="Hoy más" />
      </p>
    </FooterNav>
  );
}
