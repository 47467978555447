import * as firebase from "firebase";
import "firebase/functions";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const signInWithEmailAndPassword = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const createUserWithEmailAndPassword = (email, password) =>
  firebase
    .auth()
    .createUserWithEmailAndPassword("felipebotero@gmail.com", "asdasdasd");

export const signOut = () => firebase.auth().signOut();

export const onAuthStateChanged = (callback) =>
  firebase.auth().onAuthStateChanged(callback);

export const onSearchDriver = (text) => {
  return firebase.functions().httpsCallable("searchDrivers")({
    text,
  });
};

export const onCreateServiceOrder = (
  manifestNumber,
  total,
  advancePercentage,
  driverId
) =>
  firebase.functions().httpsCallable("createServiceOrder")({
    manifestNumber,
    total,
    advancePercentage,
    driverId,
  });
export const onEditServiceOrder = (
  manifestNumber,
  total,
  advancePercentage,
  driverId,
  serviceOrderId
) =>
  firebase.functions().httpsCallable("updateServiceOrder")({
    manifestNumber,
    total,
    advancePercentage,
    driverId,
    serviceOrderId,
  });

export const onGetServiceOrder = (serviceOrderId) =>
  firebase.functions().httpsCallable("getServiceOrder")({
    serviceOrderId,
  });

export const onCreatePaymentServiceOrder = (serviceOrderId, driverId) =>
  firebase.functions().httpsCallable("createPaymentServiceOrder")({
    serviceOrderId,
    driverId,
  });

export const createPaymentWhitValueServiceOrder = (
  serviceOrderId,
  driverId,
  paymentValue
) =>
  firebase.functions().httpsCallable("createPaymentWithValueServiceOrder")({
    serviceOrderId,
    driverId,
    paymentValue,
  });

export const onGetServiceOrders = (
  page,
  itemsPerPage,
  dateRange,
  manifestNumber,
  organizationId,
  driverId
) =>
  firebase.functions().httpsCallable("getServiceOrders")({
    page,
    itemsPerPage,
    ...(dateRange &&
      dateRange.length && {
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
      }),
    ...(manifestNumber && { manifestNumber }),
    ...(organizationId && { organizationId }),
    ...(driverId && { driverId }),
  });

export const onGetServiceOrderLog = (page, itemsPerPage, serviceOrderId) =>
  firebase.functions().httpsCallable("getServiceOrderLog")({
    page,
    itemsPerPage,
    serviceOrderId,
  });

export const onCreatePartner = (ally) =>
  firebase.functions().httpsCallable("createPartner")({ ...ally });

export const onEditPartner = (ally) =>
  firebase.functions().httpsCallable("updatePartner")({ ...ally });

export const onBlockPartner = (status = "DISABLED", partnerId) =>
  firebase.functions().httpsCallable("updatePartner")({ status, partnerId });

export const onGetAllies = (page, itemsPerPage, nameFilter) =>
  firebase.functions().httpsCallable("getPartners")({
    page,
    itemsPerPage,
    nameFilter,
  });

export const onGetAllyProfile = (partnerId) =>
  firebase.functions().httpsCallable("getPartner")(
    partnerId ? { partnerId } : {}
  );

export const onGetInvoicesPartner = (page, itemsPerPage, destinationType) =>
  firebase.functions().httpsCallable("getInvoicesPartner")({
    page,
    itemsPerPage,
    ...(destinationType && { destinationType }),
  });

export const getAllyTransactions = (
  page,
  itemsPerPage,
  dateRange,
  ownerId,
  ownerType
) =>
  firebase.functions().httpsCallable("getWalletMovements")({
    page,
    itemsPerPage,
    ...(ownerId && { ownerId }),
    ...(ownerType && { ownerType }),
    ...(dateRange &&
      dateRange.startDate &&
      dateRange.endDate && {
        dateInitSearch: dateRange.startDate,
        dateEndSearch: dateRange.endDate,
      }),
  });

export const getInvoices = (
  page,
  itemsPerPage,
  dateRange,
  destinationId,
  destinationType
) => {
  return firebase.functions().httpsCallable("getInvoices")({
    page,
    itemsPerPage,
    ...(dateRange &&
      dateRange.length && {
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
      }),
    ...(destinationType && { destinationType }),
    ...(destinationId && { destinationId }),
  });
};

export const getInvoiceDetails = (page, itemsPerPage, invoiceId) =>
  firebase.functions().httpsCallable("getInvoiceDetails")({
    page,
    itemsPerPage,
    invoiceId,
  });

export const getInvoiceTotal = () =>
  firebase.functions().httpsCallable("getInvoiceTotals")({});

export const getServiceOrdersCount = (from, to, timeZone) => {
  if (from || to) {
    return firebase.functions().httpsCallable("getServiceOrdersCount")({
      from,
      to,
    });
  }

  return firebase.functions().httpsCallable("getServiceOrdersCount")({});
};

export const getDrivers = (page, itemsPerPage, externalDrivers) => {
  return firebase.functions().httpsCallable("getDrivers")({
    page,
    itemsPerPage,
    externalDrivers,
  });
};

export const inviteDriver = (name, email, variant = "OWN") => {
  return firebase.functions().httpsCallable("inviteDriver")({
    name,
    email,
    variant,
  });
};

export const updateStatusInvoice = (invoiceId, imageBase64, newStatus) => {
  return firebase.functions().httpsCallable("updateStatusInvoice")({
    invoiceId,
    ...(imageBase64 && { imageBase64 }),
    ...(newStatus && { newStatus }),
  });
};

export const updateStatusInvoiceFromAlly = (invoiceId) => {
  return firebase.functions().httpsCallable("updateStatusInvoice")({
    invoiceId,
  });
};

export const getOrganization = (organizationId) => {
  return firebase.functions().httpsCallable("getOrganization")({
    ...(organizationId && { organizationId }),
  });
};

export const getUserInformation = () => {
  return firebase.functions().httpsCallable("getUserInformation")({});
};

export const getBranchsTrasactions = (
  page,
  itemsPerPage,
  daterange,
  partnerBranchId
) => {
  return firebase.functions().httpsCallable("getWalletMovements")({
    page,
    itemsPerPage,
    ...(daterange &&
      daterange.length && {
        dateInitSearch: daterange[0],
        dateEndSearch: daterange[1],
      }),
    ...(partnerBranchId && { partnerBranchId }),
  });
};

export const getDriverInvitations = (page, itemsPerPage) => {
  return firebase.functions().httpsCallable("getDriverInvitations")({
    page,
    itemsPerPage,
  });
};

export const getDriverInfo = (driverId) => {
  return firebase.functions().httpsCallable("getDriverInfo")({ driverId });
};

export const onGetDriverServiceOrders = (page, itemsPerPage, driverId) =>
  firebase.functions().httpsCallable("getServiceOrders")({
    page,
    itemsPerPage,
    driverId,
  });

export const getPartnerBranches = (page, itemsPerPage, nameFilter) => {
  return firebase.functions().httpsCallable("getPartnerBranches")({
    page,
    itemsPerPage,
    nameFilter,
  });
};
export const createNewPartnerBranch = (data) => {
  return firebase.functions().httpsCallable("createPartnerBranch")(data);
};
export const getBranchProducts = (branchId) => {
  return firebase.functions().httpsCallable("getProducts")(
    branchId ? { partnerBranchId: branchId } : undefined
  );
};
export const getAllyProducts = (partnerId) => {
  return firebase.functions().httpsCallable("getProducts")(
    partnerId ? { partnerId } : {}
  );
};

export const setBranchProducts = (val) => {
  return firebase.functions().httpsCallable("setBranchProducts")(val);
};

export const getOrganizationUsers = (page, itemsPerPage) =>
  firebase.functions().httpsCallable("getOrganizationUsers")({
    page,
    itemsPerPage,
  });
export const getAllyUsers = (page, itemsPerPage, type = "PARTNER_ADMIN") =>
  firebase.functions().httpsCallable("getUsers")({
    page,
    itemsPerPage,
    type,
  });
export const getUserInvitations = (page, itemsPerPage, type) =>
  firebase.functions().httpsCallable("getUserInvitations")({
    page,
    itemsPerPage,
    type,
  });

export const getOrganizations = (page, itemsPerPage, filter) =>
  firebase.functions().httpsCallable("getOrganizations")({
    page,
    itemsPerPage,
  });

export const getInvoicesFromSuperAdmin = (
  page,
  itemsPerPage,
  dateRange,
  destinationId,
  destinationType
) => {
  return firebase.functions().httpsCallable("getInvoices")({
    page,
    itemsPerPage,
    ...(dateRange &&
      dateRange.length && {
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
      }),
    ...(destinationId && { destinationId }),
    destinationType,
  });
};

export const getDriverTransactions = (
  page,
  itemsPerPage,
  dateRange,
  driverId
) =>
  firebase.functions().httpsCallable("getWalletMovements")({
    page,
    itemsPerPage,
    ...(dateRange &&
      dateRange.length && {
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
      }),
    driverId,
  });
export const getDriverTransactionsSA = (
  page,
  itemsPerPage,
  dateRange,
  ownerId,
  ownerType = "DRIVER"
) =>
  firebase.functions().httpsCallable("getWalletMovements")({
    page,
    itemsPerPage,
    ...(dateRange &&
      dateRange.length && {
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
      }),
    ownerId,
    ownerType,
  });
export const createProduct = (productData) =>
  firebase.functions().httpsCallable("createProduct")(productData);

export const getPartnerBranch = (partnerBranchId) =>
  firebase.functions().httpsCallable("getPartnerBranch")({ partnerBranchId });

export const getPaymentIntent = (page, itemsPerPage, type = "ORGANIZATION") =>
  firebase.functions().httpsCallable("getPaymentIntent")({
    page,
    itemsPerPage,
    type,
  });

export const getPaymentIntents = (
  page,
  itemsPerPage,
  dateRange,
  type = "ORGANIZATION",
  idNumber,
  name
) =>
  firebase.functions().httpsCallable("getPaymentIntents")({
    page,
    itemsPerPage,
    type,
    ...(dateRange &&
      dateRange.length && {
        dateInitSearch: dateRange[0],
        dateEndSearch: dateRange[1],
      }),
    ...(idNumber ? { idNumber } : {}),
    ...(name ? { name } : {}),
  });

export const getPaymentIntentDetail = (paymentIntentId) =>
  firebase.functions().httpsCallable("getPaymentIntentDetails")({
    paymentIntentId,
  });

export const updatePaymentStatus = (paymentId, imageBase64) =>
  firebase.functions().httpsCallable("updateStatusPaymentIntent")({
    paymentId,
    imageBase64,
  });

export const getPartnerBranchUsers = (
  page,
  itemsPerPage,
  partnerBranchId,
  invited
) =>
  firebase.functions().httpsCallable("getPartnerBranchUsers")({
    page,
    itemsPerPage,
    partnerBranchId,
    invited,
  });

export const inviteUser = (email, type, typeId) =>
  firebase.functions().httpsCallable("inviteUser")({
    email,
    type,
    ...(typeId ? { typeId } : {}),
  });

export const validateUserInvited = (email) =>
  firebase.functions().httpsCallable("validateEmail")({ email });

export const signup = (email, password) =>
  firebase.functions().httpsCallable("signup")({ email, password });
export const removeProduct = (productId, status = "DISABLED") =>
  firebase.functions().httpsCallable("updateProduct")({ productId, status });

export const updatePartnerBranch = (branchData) =>
  firebase.functions().httpsCallable("updatePartnerBranch")({ ...branchData });

export const getLogo = () => firebase.functions().httpsCallable("getLogo")();

export const getTransactionsTotalByBranch = (partnerBranchId) =>
  firebase.functions().httpsCallable("getInvoiceTotalsByPartnerBranch")({
    partnerBrachId: partnerBranchId,
  });
export const getTransactionsTotalByPartner = (partnerId) =>
  firebase.functions().httpsCallable("getInvoiceTotalsByPartner")(
    partnerId ? { partnerId: partnerId } : {}
  );

export const getSuperAdminUsers = (page, itemsPerPage) =>
  firebase.functions().httpsCallable("getSuperAdminUsers")({
    page,
    itemsPerPage,
  });

export const getInvoiceTotalOrg = (isPending) =>
  firebase.functions().httpsCallable("getInvoiceTotalsAllOrga")(
    isPending ? { isPending } : {}
  );

export const getInvoiceTotalPartner = (isPending) =>
  firebase.functions().httpsCallable("getInvoiceTotalsAllPartner")(
    isPending ? { isPending } : {}
  );

export const inviteWithCsv = (formData, token) => {
  return fetch(
    "https://us-central1-hoymas-3a474.cloudfunctions.net/inviteWithCSV",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );
};

export const getEfectyTransactions = (page, itemsPerPage) =>
  firebase.functions().httpsCallable("getPaymentIntents")({
    page,
    itemsPerPage,
    type: "EFECTY",
  });
export const getEfectyTransactionDetail = (paymentIntentId) =>
  firebase.functions().httpsCallable("getPaymentIntentDetails")({
    paymentIntentId,
  });
export const getExternalEntities = (page, itemsPerPage) =>
  firebase.functions().httpsCallable("getExternalEntities")({
    page,
    itemsPerPage,
  });
export const getEfectyPayments = (page, itemsPerPage, externalEntityId) =>
  firebase.functions().httpsCallable("getPaymentExternalEntities")({
    page,
    itemsPerPage,
    externalEntityId,
  });

export const getWalletBalance = (ownerId, ownerType, partnerBranchId) =>
  firebase.functions().httpsCallable("getWallet")({
    ...(partnerBranchId ? { partnerBranchId } : {}),
    ...(ownerId ? { ownerId } : {}),
    ...(ownerType ? { ownerType } : {}),
  });

export const addEfectyPayment = (
  datePayment,
  paymentValue,
  imageBase64,
  externalEntityId = 1
) =>
  firebase.functions().httpsCallable("createPaymentToExternalEntity")({
    datePayment,
    paymentValue,
    imageBase64,
    externalEntityId,
  });
export const refreshEfectyPayment = (paymentId) =>
  firebase.functions().httpsCallable("refreshEfectyPayment")({ paymentId });

export const updateUser = (user) =>
  firebase.functions().httpsCallable("updateUser")(user);

export const getCommissionTotal = (dateRange, type) =>
  firebase.functions().httpsCallable("getCommissionTotal")({
    dateInit: dateRange[0],
    dateEnd: dateRange[1],
    type,
  });
