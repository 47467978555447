import React from "react";
import { Timeline } from "antd";
import {
  LabelText,
  Text,
} from "../../containers/dashboard-sa/Clients/NewClient";
import styled from "styled-components";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import Spinner from "./Spinner";
import moment from "moment";

const AllyTransactions = ({ loadingTransaction, transactions = [] }) => {
  return (
    <>
      <LabelText style={{ marginBottom: 26 }}>Transacciones</LabelText>

      {loadingTransaction ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 440,
          }}
        >
          <Spinner />
        </div>
      ) : transactions.length === 0 ? (
        <LabelText style={{ textAlign: "center", marginTop: 20 }}>
          No hay Transacciones disponibles
        </LabelText>
      ) : (
        <ScrolArea height={440}>
          <Text color="#607384">{moment().format("MMMM")}</Text>
          <Timeline>
            {transactions.map((transaction, i) => (
              <Timeline.Item
                dot={<IoIosCheckmarkCircleOutline size={16} color="#1645B8" />}
                color="#1645B8"
                key={i}
              >
                <LabelText>
                  Transaccion ID#{transaction?.TransactionId}
                </LabelText>
                <p style={{ margin: 0 }}>
                  Compra de{" "}
                  <strong style={{ color: "#051A55" }}>
                    {transaction?.productName}{" "}
                  </strong>{" "}
                  por monto de
                  <strong style={{ color: "#051A55" }}>
                    {" "}
                    ${new Intl.NumberFormat("de-DE").format(transaction?.value)}
                  </strong>{" "}
                  en{" "}
                  <strong style={{ color: "#051A55" }}>
                    {transaction?.destination?.name}
                  </strong>
                </p>
                <Text size={12} color="#6E8091B3" style={{ margin: 0 }}>
                  {moment(transaction?.createdAt).format("MMMM DD - hh:mm a")}
                </Text>
              </Timeline.Item>
            ))}
          </Timeline>
        </ScrolArea>
      )}
    </>
  );
};

const ScrolArea = styled.div`
  width: 100%;
  padding: 5px;
  height: ${(props) => `${props.height}px` || "100px"};
  overflow-y: auto;
`;

export default AllyTransactions;
