import React from "react";
import { Tag } from "antd";
import moment from "moment";
import styled from "styled-components";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortDirections: ["descend"],
  },
  {
    title: "Fecha de solicitud",
    dataIndex: "createdAt",
    sortDirections: ["descend"],
    render: (createdAt) => (
      <span>{moment(createdAt).format("MMM DD-hh:mm a")}</span>
    ),
  },
  {
    title: "Fecha de pago",
    dataIndex: "paidAt",
    sortDirections: ["descend"],
    render: (paidAt) => (
      <span>
        {paidAt !== "-" ? moment(paidAt).format("MMM DD-hh:mm a") : "-"}
      </span>
    ),
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "id",
    sortDirections: ["descend"],
    render: (status) => {
      let color;
      let statusText = "";
      switch (status) {
        case "COMPLETED":
          color = "green";
          statusText = "Completada";
          break;
        case "BLOCKED":
          color = "volcano";
          statusText = "Cancelado";
          break;
        case "PROCESSING":
          color = "geekblue";
          statusText = "Procesando";
          break;
        case "PENDING":
          color = "gold";
          statusText = "Pendiente";
          break;
        default:
          color = "geekblue";
          break;
      }
      return (
        <Tag color={color} key={status}>
          {statusText}
        </Tag>
      );
    },
  },
  {
    title: "Transportador",
    dataIndex: "user",
    key: "id",
    render: (user) => (
      <span>
        {user.firstName} {user.lastName}
      </span>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Cédula",
    dataIndex: "idNumber",
    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "value",
    sortDirections: ["descend"],
    render: (value) => (
      <span>{new Intl.NumberFormat("de-DE").format(+value)}</span>
    ),
  },
];

export const columnsPayments = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sortDirections: ["descend"],
  },
  {
    title: "Fecha de pago",
    dataIndex: "paidAt",
    key: "paidAt",
    sortDirections: ["descend"],
    render: (paidAt) => (
      <span>
        {paidAt !== "-" ? moment(paidAt).format("MMM DD-hh:mm a") : "-"}
      </span>
    ),
  },
  {
    title: "Fecha adjunto comprobante",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sortDirections: ["descend"],
    render: (reportedAt) => (
      <span>
        {reportedAt !== "-" ? moment(reportedAt).format("MMM DD-hh:mm a") : "-"}
      </span>
    ),
  },
  {
    title: "Valor pagado",
    dataIndex: "value",
    key: "value",
    sortDirections: ["descend"],
    render: (value) => (
      <span>{new Intl.NumberFormat("de-DE").format(+value)}</span>
    ),
  },
  {
    title: "Acciones",
    dataIndex: "urlVoucherImage",
    key: "urlVaucherImage",
    render: (url) => (
      <AnchorStyled
        onClick={(e) => e.stopPropagation()}
        href={url}
        target="_blank"
        download
      >
        Ver comprobante
      </AnchorStyled>
    ),
  },
];

const AnchorStyled = styled.a`
  letter-spacing: 0px;
  color: #1ca892;
`;
