import React, { useState, useEffect } from "react";
import { BranchDetailLayout } from "./styledComponents";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import BranchTransactions from "./BranchTransactions";
import BranchProducts from "./BranchProducts";
import { useParams } from "react-router-dom";
import BranchProfile from "./BranchProfile";
import {
  getPartnerBranch,
  getTransactionsTotalByBranch,
} from "../../../Services/firebase";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import BranchUsers from "./BranchUsers";
import BranchUserForm from "./BranchUserForm";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { MdAttachMoney } from "react-icons/md";
import { Row, Col } from "antd";

export default function BranchDetail() {
  const [branchData, setBranchData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const { id } = useParams();
  const modal = useDashboardModal();

  const getBranchData = async (branchId) => {
    setLoading(true);
    try {
      const response = await getPartnerBranch(branchId);
      const responseTransactions = await getTransactionsTotalByBranch(branchId);
      setTotalAmount(
        `$ ${new Intl.NumberFormat("de-DE").format(responseTransactions.data)}`
      );
      setLoading(false);
      setBranchData(response.data);
    } catch (e) {
      console.log(e.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBranchData(id);
  }, [id]);

  return (
    <BranchDetailLayout>
      <DashboardSectionTitle>
        Perfil de la Sucursal ID #{id}
      </DashboardSectionTitle>
      {totalAmount && (
        <Row>
          <Col span={8}>
            <DashboardItem
              title="Total facturado en el mes"
              value={totalAmount}
              factor=""
              icon={<MdAttachMoney />}
              iconColor="#1D00FF"
              iconBg="#4605F812"
              factorStatus="up"
              style={{ marginBottom: 30 }}
            />
          </Col>
        </Row>
      )}

      <BranchProfile branchData={branchData} loading={loading} />
      <BranchTransactions />
      <BranchProducts />
      <BranchUsers modalSetting={modal} />
      <BranchUserForm modalSetting={{ title: "Invitar usuario", ...modal }} />
    </BranchDetailLayout>
  );
}
