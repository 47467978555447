import React from "react";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },

  {
    title: "Tipo de comisión",
    dataIndex: "comissionType",
    key: "id",

    sortDirections: ["descend"],
  },
  {
    title: "Nombre",
    dataIndex: "name",
    key: "id",

    sortDirections: ["descend"],
  },
  {
    title: "% de Comisión",
    dataIndex: "comissionProportion",
    key: "id",

    sortDirections: ["descend"],
  },
  {
    title: "Valor en comisiones",
    dataIndex: "comissionValue",
    key: "id",
    sortDirections: ["descend"],
  },
];
