import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columnsTransactions } from "./tableConfig";
import { getBranchsTrasactions } from "../../../Services/firebase";
import moment from "moment";
import { DatePicker } from "antd";
import styled from "styled-components";
const { RangePicker } = DatePicker;

const RangePickerStyled = styled(RangePicker)`
  margin-left: 10px;
`;

export default function BranchTransactions() {
  const { id } = useParams();
  const [branchTransactions, setBranchTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [searchBarActiveVal, setSearchbarActiveVal] = useState("");
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const onGetBranchTransactions = async (pag) => {
    setLoading(true);
    try {
      const { data } = await getBranchsTrasactions(
        pag.page,
        pag.pageSize,
        dateRange,
        parseInt(id)
      );
      const formatedRows = data.response?.data?.map((row) => {
        const formatData = {
          name: `${row?.origin?.firstName} ${row?.origin?.lastName}`,
          product: row?.productName || "-",
          date: moment(row?.createdAt).format("MMM DD, YYYY  hh:mm a"),
          mount: `$ ${new Intl.NumberFormat("de-DE").format(row?.value)}`,
        };
        return formatData;
      });
      setBranchTransactions(formatedRows);
      setPagination({
        ...pagination,
        page: data.response.pagination.currentPage,
        total: data.response.pagination.total,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetBranchTransactions(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBarActiveVal, dateRange]);
  return (
    <>
      <DashboardTable
        columns={columnsTransactions}
        tableTitle="Transacciones"
        data={branchTransactions}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        loading={loading}
        searchbarPlaceholder="Buscar sucursales"
        onChangeSearchBar={onChangeSearchbar}
        customButton={
          <RangePickerStyled
            defaultValue={[moment().subtract(30, "days"), moment()]}
            ranges={{
              Hoy: [moment(), moment()],
              "Mes en curso": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              Semana: [moment().startOf("week"), moment().endOf("week")],
              "Últimos 30 días": [moment().subtract(30, "days"), moment()],
            }}
            onChange={(dates, datesString) =>
              setDateRange([
                moment(dates[0]).startOf("day").format(),
                moment(dates[1]).endOf("day").format(),
              ])
            }
          />
        }
      />
    </>
  );
}
