import React, { useEffect, useState } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns, useSelectableRow } from "./usersTableConfig";
import Button from "../../../components/dashboard-layout/button/Button";
import { Link } from "react-router-dom";
import { getOrganizationUsers } from "../../../Services/firebase";
import usePagination from "../../../hooks/usePagination";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import UserForm from "./UserForm";

export default function Users() {
  const rowSelectable = useSelectableRow();
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const modal = useDashboardModal();

  useEffect(() => {
    const onGetOrganizationUsers = async () => {
      try {
        setLoading(true);
        const { data } = await getOrganizationUsers(page, itemsPerPage);
        setPagination(data.pagination);
        const formattedUsers = data?.users?.map((user, i) => ({
          id: i,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
          email: user?.email,
          user_type: user?.type?.split("_").join(" ").toLowerCase(),
        }));
        setUsers(formattedUsers);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    onGetOrganizationUsers();
  }, [itemsPerPage, page, setPagination]);

  return (
    <>
      <DashboardSectionTitle>Usuarios</DashboardSectionTitle>

      <DashboardTable
        columns={columns}
        rowSelectable={rowSelectable}
        tableTitle="Lista de Usuarios"
        buttonActionText="+ Crear transportador"
        rowkey="id"
        loading={loading}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        data={users}
        pagination={pagination}
        customButton={
          <>
            <Button type="primary" actionButton={modal.showModal}>
              + Crear usuario
            </Button>
          </>
        }
      />
      <UserForm modalSetting={{ ...modal, title: "Agregar un usuario" }} />
    </>
  );
}
