import styled from "styled-components";

export const OrdersContainer = styled.div`
  padding-left: 15px;
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  position: relative;
  left: 20px;
  & div {
    margin-top: 10px;
  }

  & .ant-tag-green {
    color: #00be6d;
    background-color: #00be6d17;
    border: none;
  }
`;
