import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { getUserInformation, signOut } from "./Services/firebase";

export const Context = createContext();

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const value = {
    isAuth,
    authUser,
    currentUser,
    setIsAuth,
    setAuthUser,
    removeAuth: () => {
      signOut();
      setIsAuth(false);
      setAuthUser(null);
      setCurrentUser(null)
    },
  };

  useEffect(() => {
    const onGetCurrentUser = async () => {
      try {
        const { data } = await getUserInformation();
        setCurrentUser(data.response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if(authUser) {

      onGetCurrentUser();
    }
  },[authUser])

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
