import styled from "styled-components";

const renderStatus = (status) => {
  switch (status) {
    case "warning": {
      return `
            background: #F84B051A; 
            color: #F84B05;
          `;
    }
    case "success": {
      return `
          background: #00BE6D1A; 
            color: #00BE6D;
            `;
    }
    case "error": {
      return `
            
            background: #E4032B1A; 
              color: #E4032B;
          
          `;
    }
    default: {
      return ``;
    }
  }
};

export const StatusItem = styled.div`
  height: 30px;
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  white-space: nowrap;
  ${(props) => renderStatus(props.status)}
`;
