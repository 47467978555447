import React, {useState, useEffect} from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import Button from "../../../components/dashboard-layout/button/Button";
import { useSelectableRow, columns } from "../config/clientsTable";
import { Link, useHistory } from "react-router-dom";
import {getOrganizations} from '../../../Services/firebase';

const Clients = () => {
  const rowSelectable = useSelectableRow();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({page: 1, pageSize: 10})
  const [searchBarActiveVal, setSearchbarActiveVal] = useState('');
  const history = useHistory();

  const  handleClickRow = (e, record) => {
    e.stopPropagation();
    history.push(`/clients/${record.id}`);
  }

  const onChangeItemsPerPage = (val, size) => {
    setPagination({...pagination, pageSize: val })
  }
  const onChangeSearchbar = (e) => {
      setSearchbarActiveVal(e.target.value);
  }
  const onGetClients = async (pag) => {
    setLoading(true);
    try {
      const {data} = await getOrganizations(pag.page, pag.pageSize);
      const formatedRows = data?.data?.map(row => {
        const formatData = {  
          id: row.id,
          name: row.name || '-',
          nit: row.nit || '-',
          phone: row.phoneNumber || '-',
          email: row.email || '-',     
        }; 
        return formatData;
      });
      setClients(formatedRows);
      setPagination({...pagination, page: data.pagination.currentPage,  total: data.pagination.total})
      setLoading(false);

    } catch (error) {
      console.log(error);
      setLoading(false);

    }
  }

  useEffect(()=>{

    let timeout =  setTimeout(()=>onGetClients(pagination, searchBarActiveVal), 400);

    return () => {
      clearTimeout(timeout);
    }
    
  },[searchBarActiveVal])
  const tableActionButtons = (
    <>
      <Link to="/new-client">
        <Button type="primary">+ Crear nuevo cliente</Button>
      </Link>
    </>
  );
  return (
    <>
      <DashboardSectionTitle>Clientes</DashboardSectionTitle>

      <DashboardTable
        columns={columns}
        rowSelectable={rowSelectable}
        tableTitle="Lista de clientes"
        data={clients}
        customButton={tableActionButtons}
        loading={loading}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        onClickRow={handleClickRow}
        searchbarPlaceholder="Buscar clientes"
        onChangeSearchBar={onChangeSearchbar}
        onChangeItemsPerPage={onChangeItemsPerPage}
        customButton={
                <Button type="primary">
                  <Link to={"/clients/create"}>
                    {"+ Crear cliente"}
                  </Link>
                </Button>
              }
      />
    </>
  );
};

export default Clients;
