import React from "react";
import {
  AuthInputStyle,
  AuthInputIconStyle,
  ErrorMessage,
} from "../styledComponents";

export default function AuthInput({
  inputType,
  inputName,
  classes = "",
  inputLabel,
  icon,
  errorMessage,
  error,
  placeholderText = "",
  callbackChange,
  dataValue = "",
  onclickIcon,
}) {
  const iconComponent = icon ? (
    <AuthInputIconStyle onClick={onclickIcon}> {icon}</AuthInputIconStyle>
  ) : (
    ""
  );

  return (
    <React.Fragment>
      <AuthInputStyle error={error}>
        <label htmlFor={inputName}>{inputLabel}</label>
        <input
          type={inputType}
          name={inputName}
          id={inputName}
          className={classes}
          placeholder={placeholderText}
          onChange={callbackChange}
          value={dataValue}
        />
        {iconComponent}
      </AuthInputStyle>
      <ErrorMessage error={error}>{errorMessage}</ErrorMessage>
    </React.Fragment>
  );
}
