export const screen = {
  LG: "1920px",
  MD: "1366px",
  SM: "864px",
  XS: "414px",
};

export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const commissionTypes = [
  {
    type: "AMOUNT_PER_UNIT",
    label: "Pesos",
  },
  { type: "PERCENT_PER_TOTAL", label: "Porcentaje" },
];

function generateRoute(route, action = "") {
  return function (routeOptions) {
    switch (action) {
      case "READ":
        return `${route}`;
      case "CREATE":
        return `${route}/create`;
      case "EDIT":
        return `${route}/${routeOptions.id}/edit`;
      default:
        return `${route}/${routeOptions.id}/${action}`;
    }
  };
}
export const ROUTES = {
  allAllies: generateRoute("/allies", "READ"),
  createAllies: generateRoute("/allies", "CREATE"),
  editAllies: generateRoute("/allies", "EDIT"),
  ally: generateRoute("/allies"),
};

export const PAYMENT_INTENT_STATUSES = {
  PENDING: "Pendiente",
  PROCESSING: "Procesando",
  BLOCKED: "Bloqueada",
  COMPLETED: "Completada",
};

export const DRIVER_VARIANT = {
  OWN: "Propio",
  OUTSOURCED: "Tercero",
};
