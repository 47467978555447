import { useState, useEffect } from "react";

const useInput = (initialValue = "", submitting, validateLength) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!value && submitting) {
      setError("Este campo es requerido");
    } else if (validateLength && value.length > 10 && submitting) {
      setError("Debe contener máximo 10 carácteres.")
    } else {
      setError("");
    }
  }, [submitting, value]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onFormatCurrency = (e) => {
    setValue(e.target.value.replace(/[.]/g, ""));
  };

  return {
    value,
    error,
    onChange,
    setError,
    setValue,
    onFormatCurrency,
    onSetValue: setValue
  };
};

export default useInput;
