import React, { useContext, useEffect, useState } from "react";

import {
  DashboardNavLayout,
  MenuDashboard,
  SubmenuDash,
} from "../styledComponents";
import Footer from "../footer/Footer";
import saLogo from "../../../assets/img/saLogo.png";
import { MdDashboard, MdGroup, MdAccountCircle, MdClose } from "react-icons/md";
import { RiCoinsLine } from "react-icons/ri";
import { IoMdTrendingUp } from "react-icons/io";
import {
  FaTruck,
  FaFileInvoiceDollar,
  FaShoppingBag,
  FaUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsPeopleFill } from "react-icons/bs";
import { Context } from "../../../Context";
import { AiFillShop } from "react-icons/ai";
import { getLogo } from "../../../Services/firebase";
import Spinner from "../../dashboard-SA/Spinner";
import { ROUTES } from "../../../utils/constants";

function DashboardNav({ responsive, showNav, handleOpenCloseNav }) {
  const { currentUser, authUser } = useContext(Context);
  const [logoUri, setLogoUri] = useState(null);
  const [loading, setLoading] = useState(false);
  const responsiveCloseNav = () =>
    responsive ? (
      <MenuDashboard.Item key="7" onClick={handleOpenCloseNav}>
        <span className="icon-menu-container">
          <MdClose />
          <span>Cerrar menú</span>
        </span>
      </MenuDashboard.Item>
    ) : (
      ""
    );
  const onGetLogo = async () => {
    setLoading(true);
    try {
      const response = await getLogo();
      setLogoUri(response?.data?.url);
      setLoading(false);
    } catch (e) {
      console.log(e.data?.error || e.message || e);
      setLoading(false);
    }
  };
  useEffect(() => {
    onGetLogo();
  }, []);
  return (
    <DashboardNavLayout showNav={showNav} responsiveNav={responsive}>
      {!loading ? (
        <img
          src={logoUri || saLogo}
          alt="hoy+"
          style={{ width: "160px", margin: " 20px 0" }}
        />
      ) : (
        <div
          style={{
            width: "160px",
            margin: " 20px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner color="white" />
        </div>
      )}

      {currentUser?.type === "SUPER_ADMIN" && (
        <MenuDashboard
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <MenuDashboard.Item key="1">
            <span className="icon-menu-container">
              <MdDashboard />
              <span>
                <Link to="/">Dashboard</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="2">
            <span className="icon-menu-container">
              <FaShoppingBag />
              <span>
                <Link to={ROUTES.allAllies()}>Aliados</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="3">
            <span className="icon-menu-container">
              <BsPeopleFill />
              <span>
                <Link to="/clients">Clientes</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="4">
            <span className="icon-menu-container">
              <FaTruck />
              <span>
                <Link to="/transporters">Transportadores</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="9">
            <span className="icon-menu-container">
              <RiCoinsLine />
              <span>
                <Link to="/efecty">Efecty</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="9">
            <span className="icon-menu-container">
              <IoMdTrendingUp />
              <span>
                <Link to="/comisiones">Comisiones</Link>
              </span>
            </span>
          </MenuDashboard.Item>

          <SubmenuDash
            key="sub1"
            icon={<FaFileInvoiceDollar />}
            title="Facturación"
          >
            <MenuDashboard.Item key="5">
              <span className="icon-menu-container">
                <span>
                  <Link to="/invoices/organization">Facturas a clientes</Link>
                </span>
              </span>
            </MenuDashboard.Item>
            <MenuDashboard.Item key="6">
              <span className="icon-menu-container">
                <span>
                  <Link to="/invoices/allies">Facturas a aliados</Link>
                </span>
              </span>
            </MenuDashboard.Item>
          </SubmenuDash>
          <MenuDashboard.Item key="8">
            <span className="icon-menu-container">
              <FaUser />
              <span>
                <Link to="/users">Usuarios</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          {/*<MenuDashboard.Item key="7">
            <span className="icon-menu-container">
              <MdAccountCircle />
              <span>
                <Link to="/profile">Perfil</Link>
              </span>
            </span>
          </MenuDashboard.Item> */}
          {responsiveCloseNav()}
        </MenuDashboard>
      )}

      {currentUser?.type === "PARTNER_ADMIN" && (
        <MenuDashboard
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <MenuDashboard.Item key="1">
            <span className="icon-menu-container">
              <MdDashboard />
              <span>
                <Link to="/">Dashboard</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="2">
            <span className="icon-menu-container">
              <AiFillShop />
              <span>
                <Link to="/branchs">Sucursales</Link>
              </span>
            </span>
          </MenuDashboard.Item>

          <MenuDashboard.Item key="3">
            <span className="icon-menu-container">
              <FaFileInvoiceDollar />
              <span>
                <Link to="/invoices">Facturación</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="4">
            <span className="icon-menu-container">
              <MdAccountCircle />
              <span>
                <Link to="/profile">Perfil</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="8">
            <span className="icon-menu-container">
              <FaUser />
              <span>
                <Link to="/users">Usuarios</Link>
              </span>
            </span>
          </MenuDashboard.Item>

          {responsiveCloseNav()}
        </MenuDashboard>
      )}

      {currentUser?.type === "ORGANIZATION_ADMIN" ? (
        <MenuDashboard
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <MenuDashboard.Item key="1">
            <span className="icon-menu-container">
              <MdDashboard />
              <span>
                <Link to="/">Dashboard</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="2">
            <span className="icon-menu-container">
              <FaTruck />
              <span>
                <Link to="/transporters">Transportadores</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="3">
            <span className="icon-menu-container">
              <FaFileInvoiceDollar />
              <span>
                <Link to="/invoices">Facturación con Hoy +</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="4">
            <span className="icon-menu-container">
              <MdGroup />
              <span>
                <Link to="/users">Usuarios</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          <MenuDashboard.Item key="5">
            <span className="icon-menu-container">
              <MdAccountCircle />
              <span>
                <Link to="/profile">Perfil</Link>
              </span>
            </span>
          </MenuDashboard.Item>
          {responsiveCloseNav()}
        </MenuDashboard>
      ) : null}

      <Footer />
    </DashboardNavLayout>
  );
}

export default DashboardNav;
