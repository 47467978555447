import React, { useContext } from "react";
import {
  DashboardSectionTitle,
  DashboardCardText,
  Text,
  HistoryItem,
} from "../../../components/dashboard-layout/styledComponents";
import { Divider, Timeline, Spin } from "antd";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Context } from "../../../Context";
import moment from "moment";

export default function ServiceOrderHistory({
  log,
  loadingLog,
  antIconLoading,
  serviceOrder,
}) {
  const { authUser, currentUser } = useContext(Context);

  return (
    <>
      <Divider />
      <DashboardSectionTitle style={{ fontSize: 16 }}>
        Historial
      </DashboardSectionTitle>
      {loadingLog ? (
        <Spin indicator={antIconLoading} />
      ) : (
        <HistoryItem>
          <Timeline>
            {log.length > 0 &&
              log.reverse().map((item, idx) => {
                if (idx === 0) {
                  return (
                    <Timeline.Item
                      dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
                      color="#00BE6D"
                      key={idx}
                    >
                      <DashboardCardText
                        style={{
                          width: 753,
                          color: "#0A181D",
                          marginBottom: 5,
                        }}
                      >
                        Solicitud <strong>#{item.ServiceOrderId}</strong> creada
                        por{" "}
                        <strong>
                          {`${currentUser?.firstName} ${currentUser?.lastName}` ||
                            "Dashboard User"}{" "}
                          - {authUser?.email}
                        </strong>{" "}
                        para el manifiesto{" "}
                        <strong>#{serviceOrder?.manifestNumber}</strong>{" "}
                        Conductor{" "}
                        <strong>
                          {item.data?.data?.driverFirtsName}{" "}
                          {item.data?.data?.driverLastName}
                        </strong>
                        con un porcentaje de anticipo de{" "}
                        <>{serviceOrder?.advancePercentage}%</>
                      </DashboardCardText>
                      <Text size={12} color="#949494" style={{ margin: 0 }}>
                        {moment(item.createdAt).format("MMM DD - hh:mm a")}
                      </Text>
                    </Timeline.Item>
                  );
                }

                if (idx === 1) {
                  return (
                    <Timeline.Item
                      dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
                      color="#00BE6D"
                      key={idx}
                    >
                      <DashboardCardText
                        style={{
                          width: 753,
                          color: "#0A181D",
                          marginBottom: 5,
                        }}
                      >
                        Anticipo generado por
                        {`${currentUser?.firstName} ${currentUser?.lastName}` ||
                          "Dashboard User"}{" "}
                        para el manifiesto{" "}
                        <strong>#{serviceOrder?.manifestNumber}.</strong>
                      </DashboardCardText>
                      <Text size={12} color="#949494" style={{ margin: 0 }}>
                        {moment(item.createdAt).format("MMM DD - hh:mm a")}
                      </Text>
                    </Timeline.Item>
                  );
                }

                if (idx > 1) {
                  return (
                    <Timeline.Item
                      dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
                      color="#00BE6D"
                      key={idx}
                    >
                      <DashboardCardText
                        style={{
                          width: 753,
                          color: "#0A181D",
                          marginBottom: 5,
                        }}
                      >
                        Pago restante generado por{" "}
                        {`${currentUser?.firstName} ${currentUser?.lastName}` ||
                          "Dashboard User"}{" "}
                        al conductor{" "}
                        <strong>
                          {item.data?.data?.driverFirtsName}{" "}
                          {item.data?.data?.driverLastName}{" "}
                        </strong>
                        por un monto de{" "}
                        <strong>
                          $
                          {new Intl.NumberFormat("de-DE").format(
                            item.data?.data?.transactionValue
                          )}
                        </strong>
                      </DashboardCardText>
                      <Text size={12} color="#949494" style={{ margin: 0 }}>
                        {moment(item.createdAt).format("MMM DD - hh:mm a")}
                      </Text>
                    </Timeline.Item>
                  );
                }
              })}
          </Timeline>
        </HistoryItem>
      )}
    </>
  );
}
