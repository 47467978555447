import React from "react";
import {
  FlexContainerHelper,
  DashboardCardTitle,
  Text,
} from "../../../components/dashboard-layout/styledComponents";
import { Avatar } from "antd";
import { ProfileContainer, ProfileTitle, CardBlue } from "./styledComponents";
import { IdcardOutlined } from "@ant-design/icons";
import { MdCheckCircle } from "react-icons/md";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { DRIVER_VARIANT } from "../../../utils/constants";

export default function Profile({ loading, driver }) {
  return loading ? (
    <div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 300,
      }}
    >
      <Spinner />
    </div>
  ) : (
    <ProfileContainer>
      <FlexContainerHelper align="center">
        <Avatar size={46} src={driver?.urlProfileImage} />
        <div style={{ marginRight: "5px" }}>
          <ProfileTitle>
            {driver?.User?.firstName} {driver?.User?.lastName}
          </ProfileTitle>
          {/* <span>
              <Link
                style={{ color: "#585858", textDecoration: "underline" }}
                to={`transporter/edit/:id`}
              >
                Editar perfil
              </Link>
            </span> */}
        </div>
      </FlexContainerHelper>
      <div></div>
      <div>
        <DashboardCardTitle>Información Básica</DashboardCardTitle>
        <FlexContainerHelper justify="space-between">
          <span>Cédula</span>
          <strong>
            <span>{driver?.idNumber}</span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Número de celular</span>
          <strong>
            <span>
              {driver?.phoneNumber.replace(
                /(\W)(\d{2})(\d{3})(\d{3})(\d{4})/g,
                "$1$2 $3 $4 $5"
              )}
            </span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Correo electrónico</span>
          <strong>
            <span>{driver?.User?.email}</span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Tipo</span>
          <strong>
            <span>{DRIVER_VARIANT[driver?.variant]}</span>
          </strong>
        </FlexContainerHelper>
        {/* <FlexContainerHelper justify="space-between">
          <span>Dirección</span>
          <strong>
            <span>{driver?.address}</span>
          </strong>
        </FlexContainerHelper> */}
      </div>
      <div>
        <DashboardCardTitle>Información Bancaria</DashboardCardTitle>
        <CardBlue>
          <FlexContainerHelper justify="space-between">
            <span>Nombre del Banco</span>
            <strong>
              <span>{driver?.BankAccount?.Bank?.bankName}</span>
            </strong>
          </FlexContainerHelper>
          <FlexContainerHelper justify="space-between">
            <span>Tipo de cuenta</span>
            <strong>
              <span>{driver?.BankAccount?.accountType}</span>
            </strong>
          </FlexContainerHelper>
          <FlexContainerHelper justify="space-between">
            <span>Numero de Cuenta</span>
            <strong>
              <span>{driver?.BankAccount?.accountNumber}</span>
            </strong>
          </FlexContainerHelper>
        </CardBlue>
      </div>
      {/* <div>
        <DashboardCardTitle>Información del vehículo</DashboardCardTitle>
        <FlexContainerHelper justify="space-between">
          <span>Tipo de Camión</span>
          <strong>
            <span>Camión de dos ejes</span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Placa</span>
          <strong>
            <span>CBA -170</span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Modelo</span>
          <strong>
            <span>Chevrolet NPR 2020</span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Tipo de trailer</span>
          <strong>
            <span>Chevrolet NPR 2020</span>
          </strong>
        </FlexContainerHelper>
        <FlexContainerHelper justify="space-between">
          <span>Capacidad Tonelaje</span>
          <strong>
            <span>17 ton</span>
          </strong>
        </FlexContainerHelper>
      </div> */}
      <div>
        <DashboardCardTitle>Documentos</DashboardCardTitle>
        {driver?.Documents?.map((document, i) => (
          <a
            href={document?.content}
            download
            target="_blank noopener noreferrer"
          >
            <CardBlue style={{ marginBottom: 10 }}>
              <FlexContainerHelper justify="space-between">
                <IdcardOutlined className="main-icon" />
                <div>
                  <div style={{ margin: "0", padding: 0, color: "#0A181D" }}>
                    {document?.type === "ID_IMAGE_FRONT"
                      ? "Cédula por adelante"
                      : "Cédula por atras"}
                  </div>
                  {/* <span style={{ color: "#6E8091B3" }}>
                Fecha de vencimiento: 20/12/2020
              </span> */}
                </div>

                <Text color="#00BE6D" display="flex" alignment="flex-end">
                  <MdCheckCircle />
                  Verificado
                </Text>
              </FlexContainerHelper>
            </CardBlue>
          </a>
        ))}
        {/*         
        <CardBlue>
          <FlexContainerHelper justify="space-between">
            <FileOutlined className="main-icon" />
            <div>
              <div style={{ margin: "0", padding: 0, color: '#0A181D' }}>Licencia de Conducir</div>
              <span style={{ color: "#6E8091B3" }}>
                Fecha de vencimiento: 20/12/2020
              </span>
            </div>

            <Text color="#00BE6D" display="flex" alignment="flex-end">
              <MdCheckCircle />
              Verificado
            </Text>
          </FlexContainerHelper>
        </CardBlue> */}
      </div>
    </ProfileContainer>
  );
}
