import styled from "styled-components";
import { Input } from "antd";

export const SearchInput = styled(Input)`
  border: none !important;
  box-shadow: none !important;
  background-color: ${(props) => props.bcolor};
  width: ${(props) => props.elmwidth};
  border-right-width: none !important;
  -webkit-box-shadow: none !important;
  margin-left: 1em;
  & :focus,
  :hover {
    border: none !important;
    box-shadow: none !important;
    border-right-width: none !important;
    -webkit-box-shadow: none !important;
    background-color: ${(props) => props.bcolor};
  }
  & input {
    background-color: ${(props) => props.bcolor};
  }
`;
